import React from 'react';

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of the year
    return `${day}/${month}/${year}`;
};

export default function History({ appointment }) {
    return (
        <div className="card mb-6">
            <div className="card h-100">
                <div className="card-body  flex-wrap gap-4">
                    <div className="d-flex align-items-center gap-3">
                        {/* <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                                <i className="ri-calendar-check-line ri-24px" />
                            </div>
                        </div> */}
                        <div className="card-info w-100">
                            <h5 className="mb-6 ">Appointment History</h5>
                            {appointment.status === 'completed' ? (
                                <div className="table-responsive">
                                    <table className="table table-bordered mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Scheduled</th>
                                                <th>Completed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{formatDate(appointment.created_at)}</td>
                                                <td>{formatDate(appointment.updated_at)}</td>
                                            </tr>
                                            {/* Add any additional history details here */}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="mb-0 text-danger">No history found</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
