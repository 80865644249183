import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import IncomingCall from '../../helper/incomingcallmodel';
import axios from 'axios';
import { useAuth } from '../utils/loggedin';

function randomMeetingID(len = 5) {
    const chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP';
    return Array(len).fill().map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
}

export default function DoctorStatus({ doctorData }) {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [isOnline, setIsOnline] = useState(false);
    const [callRequest, setCallRequest] = useState(null);
    const [socket, setSocket] = useState(null);


    useEffect(() => {
        if (doctorData) {
            setIsOnline(doctorData.status);
            console.log('Initial doctor status:', doctorData.status);
        }
    }, [doctorData]);

    useEffect(() => {
        if (token) {
            const socketInstance = io(process.env.REACT_APP_API_URL, {
                auth: {
                    token: token // Passing token directly
                }
            });
            setSocket(socketInstance);

            return () => {
                socketInstance.disconnect(); // Clean up when component unmounts
            };
        }
    }, [token]);

    useEffect(() => {
        if (socket) {
            console.log('Setting up socket listeners');

            socket.on('connect', () => {
                console.log('Socket connected:', socket.id);
            });

            socket.on('disconnect', (reason) => {
                console.log('Socket disconnected:', reason);
            });

            socket.on('incomingCall', (data) => {
                console.log('Incoming call received:', data);
                setCallRequest(data);
                window.focus();

                // Show browser notification
                if ('Notification' in window) {
                    Notification.requestPermission().then(permission => {
                        if (permission === 'granted') {
                            new Notification('Incoming Call', {
                                body: `Call from ${data.userData.first_name} ${data.userData.last_name}`,
                                icon: '/path/to/notification-icon.png' // Add an appropriate icon path
                            });
                        }
                    });
                }

                toast.info(`📞 Incoming call from ${data.userData.first_name} ${data.userData.last_name}`, {
                    position: "bottom-right",
                    style: {
                        fontSize: '16px',
                        padding: '20px',
                        backgroundColor: '#f0f4f8',
                        border: '1px solid #007BFF',
                        borderRadius: '8px',
                        color: '#333',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    },
                    icon: '📞',
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            });

            socket.on('callRequest', (data) => {
                console.log('Call request received:', data);
                if (doctorData?.id === data.doctorId) {
                    setCallRequest(data);
                    window.focus();

                    // Show browser notification for call request
                    if ('Notification' in window) {
                        Notification.requestPermission().then(permission => {
                            if (permission === 'granted') {
                                new Notification('Call Request', {
                                    body: `New call request from a patient`,
                                    icon: '/path/to/notification-icon.png' // Add an appropriate icon path
                                });
                            }
                        });
                    }
                }
            });
            socket.on('callRequest', (data) => {
                console.log('Call request received:', data);
                if (doctorData?.id === data.doctorId) {
                    setCallRequest(data);
                    window.focus();
                }
            });

            socket.on('receiveMeetingId', (data) => {
                console.log('Received meeting ID:', data);
            });

            socket.on('callRequestSent', (data) => {
                console.log(`Call request sent confirmation to Doctor ${data.doctorId}`);
                toast.info(`Call request sent to Doctor ${data.doctorId}`, { position: "bottom-right" });
            });

            socket.on('error', (error) => {
                console.error('Socket error:', error);
            });

            return () => {
                console.log('Cleaning up socket listeners');
                socket.off('connect');
                socket.off('disconnect');
                socket.off('callRequest');
                socket.off('receiveMeetingId');
                socket.off('callRequestSent');
                socket.off('incomingCall');
                socket.off('callRequest');
                socket.off('callResponse');
                socket.off('updateStatus');
                socket.off('error');
            };
        }
    }, [doctorData, socket]);

    const updateStatusAPI = async (status) => {
        try {
            console.log('Updating doctor status via API:', status);
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/doctor/updatedoctorstatus`, { status }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error updating doctor status:', error);
        }
    };

    const handleStatusChange = (status) => {
        // updateStatusAPI(status);
        setIsOnline(status);
        console.log('Emitting doctorStatusUpdate event');
        socket.emit('updateStatus', {
            // doctorId: doctorData.id,
            status,
            // doctorData: {
            //     id: doctorData.id,
            //     first_name: doctorData.first_name,
            //     last_name: doctorData.last_name
            // }
        });
        const statusText = status ? 'Now Online' : 'Now Offline';
        const toastStatus = status ? toast.success : toast.error;
        toastStatus(statusText, { position: "bottom-right" });
    };

    const handleCallAccept = () => {
        const meetingID = randomMeetingID(6);
        console.log('Call accepted, generated meeting ID:', meetingID);
        socket.emit('callResponse', { userId: callRequest.userId, accepted: true, meetingID: `${window.location.protocol}//${window.location.host}/meeting?roomID=${meetingID}&name=${callRequest.userData.first_name} ${callRequest.userData.last_name}` });
        socket.emit('sendMeetingId', {
            userId: callRequest.userData.user_id,
            meetingId: `${window.location.protocol}//${window.location.host}/meeting?roomID=${meetingID}&name=${callRequest.userData.first_name} ${callRequest.userData.last_name}`
        });
        window.open(`${window.location.protocol}//${window.location.host}/meeting?roomID=${meetingID}&name=${doctorData.first_name} ${doctorData.last_name}`, '_blank');
        console.log(callRequest.userData.user_id);
        navigate('/prescription', { state: { user_id: callRequest.userId } });
        setCallRequest(null);
    };

    const handleCallDecline = () => {
        console.log('Call declined');
        socket.emit('callResponse', { accepted: false, userId: callRequest.userId });
        setCallRequest(null);
    };
    const fetchMeetingHistory = (filters = {}) => {
        if (socket) {
            console.log('Requesting meeting history with filters:', filters);
            socket.emit('getDoctorMeetingHistory', filters);
        } else {
            console.error('Socket not initialized');
        }
    };

    useEffect(() => {
        if (socket && doctorData) {
            fetchMeetingHistory();  // Fetch all history
            // Or with filters:
            // fetchMeetingHistory({ startDate: '2023-01-01', endDate: '2023-12-31', status: 'completed' });
        }
    }, [socket, doctorData]);

    return (
        <>
            <div className='status-set-btn'>
                <i class="ri-notification-2-line me-3"></i>
                <label className="switch switch-success">
                    <input
                        type="checkbox"
                        checked={isOnline}
                        onChange={(e) => handleStatusChange(e.target.checked)}
                        className="switch-input"
                    />
                    <span className="switch-toggle-slider">
                        <span className="switch-on">
                            <i className="ri-check-line" />
                        </span>
                        <span className="switch-off">
                            <i className="ri-close-line" />
                        </span>
                    </span>
                    <span className="switch-label">Status</span>
                </label>
            </div>
            <IncomingCall
                callRequest={callRequest}
                onAccept={handleCallAccept}
                onDecline={handleCallDecline}
            />
        </>
    );
}