import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Remarks({ prescriptionId }) {
  const [remarks, setRemarks] = useState('');

  const updateRemarks = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/prescription/remarks/${prescriptionId}`, { remarks }, {
        headers: {
          "Signature": process.env.REACT_APP_API_SIGNATURE
        }
      });
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      <textarea
        className='w-100 p-2'
        rows={20}
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
      />
      <button className='btn btn-primary' onClick={updateRemarks}>
        Update Remarks
      </button>
    </div>
  );
}

