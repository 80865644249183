import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [doctorData, setDoctorData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = Cookies.get('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const fetchDoctorData = async () => {
    if (!token) return;

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/getdoctor`, {
        headers: {
          "Signature": process.env.REACT_APP_API_SIGNATURE,
          "Authorization": `Bearer ${token}`,
        },
      });
      const data = response.data.doctor;

      // Check if critical fields are missing in the doctor data
      if (!data.first_name || !data.specializations || !data.email || !data.phone || !data.experience || !data.qualification || !data.doctor_type || !data.mci_number) {  // Add your own validation logic
        navigate('/profile'); // Navigate to profile page if required fields are missing
      } else {
        setDoctorData(data);
      }
    } catch (error) {
      console.error('Error fetching doctor data:', error);
      logout();
    }
  };

  useEffect(() => {
    if (token) {
      fetchDoctorData();
    }
  }, [token]);

  const login = (newToken, rememberMe) => {
    setToken(newToken);
    setIsAuthenticated(true);
    const expires = rememberMe ? 30 : 7; // Days
    Cookies.set('token', newToken, { path: '/', expires });
  };

  const logout = () => {
    Cookies.remove('token');
    setToken(null);
    setIsAuthenticated(false);
    setDoctorData(null);
    navigate('/login', { replace: true });
    window.location.reload();
  };

  const authContextValue = {
    isAuthenticated,
    token,
    login,
    logout,
    loading,
    doctorData,
    fetchDoctorData,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {loading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
