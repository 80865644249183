import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import { toast } from "react-toastify";

export default function AllAppointments({ appointments, loading }) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showCompleteModel, setShowCompleteModel] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [searchType, setSearchType] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [searchCardNumber, setSearchCardNumber] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);


    function ViewAppointments(appointmentId) {
        navigate('/appointments/view', { state: { appointmentId } });
    }

    function openRejectModal(appointment) {
        setSelectedAppointment(appointment);
        setShowRejectModal(true);
    }

    function closeRejectModal() {
        setShowRejectModal(false);
        setSelectedAppointment(null);
    }

    function openApproveModal(appointment) {
        setSelectedAppointment(appointment);
        setShowApproveModal(true);
    }

    function closeApproveModal() {
        setShowApproveModal(false);
        setSelectedAppointment(null);
    }
    function openCompleteModal(appointment) {
        setSelectedAppointment(appointment);
        setShowCompleteModel(true);
    }

    function closeCompleteModal() {
        setShowCompleteModel(false);
        setSelectedAppointment(null);
    }

    function handleRejectButtonClick() {
        if (selectedAppointment) {
            const { id, status } = selectedAppointment;
            if (status === 'rejected') {
                toast("Appointment Already Rejected");
                closeRejectModal();
                return;
            }
            rejectAppointment(id, closeRejectModal);
        }
    }

    function handleApproveButtonClick() {
        if (selectedAppointment) {
            const { id, status } = selectedAppointment;
            if (status === 'approved') {
                toast("Appointment Already Approved");
                closeApproveModal();
                return;
            }
            approveAppointment(id, closeApproveModal);
        }
    }
    function handleCompleteButtonClick() {
        if (selectedAppointment) {
            const { id, status } = selectedAppointment;
            if (status === 'completed') {
                toast("Appointment Already Completed");
                closeCompleteModal();
                return;
            }
            completeAppointment(id, closeApproveModal);
        }
    }

    const rejectAppointment = async (appointmentId, closeRejectModal) => {
        setIsLoading(true); // Set loading to true when starting the API call

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/appointments/rejectappointment/${appointmentId}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });

            toast.success("Appointment Rejected Successfully");
            // Optionally, update your UI or state to reflect the rejected status
            window.location.reload();
        } catch (error) {
            // Check if the error response indicates that the appointment is already completed
            if (error.response && error.response.status === 400 && error.response.data.message === "Cannot reject an already completed appointment") {
                toast.error("Cannot reject an already completed appointment");
            } else {
                console.error('Error rejecting appointment:', error);
                toast.error('Error rejecting appointment. Please try again later.');
            }
        } finally {
            setIsLoading(false); // Set loading to false once the API call is completed
            closeRejectModal();
        }
    };


    // complete appointment
    function completeAppointment(appointmentId, closeModal) {
        setIsLoading(true)
        axios.put(`${process.env.REACT_APP_API_URL}/appointments/${appointmentId}/complete`,
            {}, // No body needed for this request
            {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                toast.success("Appointment Completed Successfully");
                // Optionally, update your UI or state to reflect the completed status
            })
            .catch(error => {
                console.error('Error completing appointment:', error);
                toast.error(error?.response?.data?.message);
            })
            .finally(() => {
                setIsLoading(false)
                closeModal();

            });
        window.location.reload();
    }


    function approveAppointment(appointmentId, closeModal) {
        setIsLoading(true)
        axios.post(
            `${process.env.REACT_APP_API_URL}/appointments/${appointmentId}/approve`,
            {},
            {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then(response => {
                toast.success("Appointment Approved Successfully");
                // Optionally, update your UI or state to reflect the approved status
                window.location.reload(); // Reload to update the UI
            })
            .catch(error => {
                if (error.response && error.response.status === 400 && error.response.data.message === 'Cannot approve an already completed appointment') {
                    toast.error("Cannot approve an already completed appointment");
                } else {
                    console.error('Error approving appointment:', error);
                    toast.error("Error approving appointment. Please try again later.");
                }
            })
            .finally(() => {
                setIsLoading(false)
                closeModal(); // Close the modal regardless of the outcome
            });
    }


    // Filter appointments based on search inputs
    const filteredAppointments = appointments.filter(appointment => {
        return (
            appointment.appointment_type.toLowerCase().includes(searchType.toLowerCase()) &&
            appointment.status.toLowerCase().includes(searchStatus.toLowerCase()) && appointment.appointment_id.toLowerCase().includes(searchCardNumber.toLowerCase())
        );
    });

    // Pagination calculations
    const indexOfLastAppointment = currentPage * itemsPerPage;
    const indexOfFirstAppointment = indexOfLastAppointment - itemsPerPage;
    const currentAppointments = filteredAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);
    const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    const formatTime = (timeString) => {
        // Assume timeString is in 'HH:mm:ss' format
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        return `${day}/${month}/${year}`;
    };

    return (
        <div className="dataTables_wrapper dt-bootstrap5 no-footer">


            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="DataTables_Table_0_length " >
                        <label>
                            Show{" "}
                            <select
                                name="DataTables_Table_0_length"
                                aria-controls="DataTables_Table_0"
                                className="form-select form-select-sm"
                                value={itemsPerPage}
                                onChange={(e) => {
                                    setItemsPerPage(Number(e.target.value));
                                    setCurrentPage(1); // Reset to the first page when items per page changes
                                }}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={75}>75</option>
                                <option value={100}>100</option>
                            </select>{" "}
                            entries
                        </label>
                    </div>
                </div>
            </div>


            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Appointment Number</th>
                        <th>Appointment Type</th>
                        <th>Status</th>
                        <th>Time Slot</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>

                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Card Number"
                                value={searchCardNumber}
                                onChange={(e) => setSearchCardNumber(e.target.value)}
                            />
                        </td>
                        <td ><input
                            type="text"
                            placeholder="Search by Appointment Type"
                            value={searchType}
                            onChange={(e) => setSearchType(e.target.value)}
                            className="form-control form-control-sm "
                        /></td>
                        <td className="dataTables_length" id="DataTables_Table_0_length" >
                            <label className="d-flex mb-0 align-items-center gap-2">

                                <select
                                    name="DataTables_Table_0_status"
                                    aria-controls="DataTables_Table_0"
                                    className="form-select form-select-sm"
                                    value={searchStatus}
                                    onChange={(e) => {
                                        setSearchStatus(e.target.value);
                                        // Perform any additional actions like filtering based on the status here
                                    }}
                                >
                                    <option value="">Select Status</option>
                                    <option value="approved">Approved</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="completed">Completed</option>
                                    <option value="rejected">Rejected</option>
                                </select>{" "}
                            </label>
                        </td>




                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {loading ? (
                        <tr>
                            <td colSpan="6">Loading...</td>
                        </tr>
                    ) : currentAppointments.length > 0 ? (
                        currentAppointments.map((appointment, index) => (
                            <tr key={index} >
                                <td className="me-0">{indexOfFirstAppointment + index + 1}</td>
                                <td>{appointment.appointment_id}</td>
                                <td>{appointment.appointment_type}</td>
                                <td>
                                    <div
                                        className={`badge rounded-pill mt-1 ${appointment.status === 'completed' || appointment.status === 'approved'
                                            ? 'bg-label-success'
                                            : appointment.status === 'rejected'
                                                ? 'bg-label-danger'
                                                : 'bg-label-primary' // default class for 'scheduled' status
                                            }`}
                                    >
                                        {appointment.status}
                                    </div>
                                </td>
                                <td>
                                    {formatTime(appointment.arrival_time) + ' - ' + formatTime(appointment.departure_time)}
                                </td>
                                <td>{formatDate(appointment.date)}</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            type="button"
                                            className="btn p-0 dropdown-toggle hide-arrow"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="ri-more-2-line" />
                                        </button>
                                        <div className="dropdown-menu" style={{ width: "20%" }}>
                                            <button
                                                className="dropdown-item waves-effect"
                                                onClick={() => ViewAppointments(appointment.id)}
                                            >
                                                <i className="ri-eye-line me-2" /> View
                                            </button>
                                            <button
                                                className="dropdown-item waves-effect"
                                                onClick={() => openApproveModal(appointment)}
                                            >
                                                <i className="ri-check-line me-2" /> Approve
                                            </button>
                                            <button
                                                className="dropdown-item waves-effect"
                                                onClick={() => openRejectModal(appointment)}
                                            >
                                                <i className="ri-delete-bin-7-line me-2 align-middle"></i> Reject
                                            </button>
                                            <button
                                                className="dropdown-item waves-effect"
                                                onClick={() => openCompleteModal(appointment)}
                                            >
                                                <i className="ri-check-line me-2 align-middle"></i> Completed
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                No Data Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination controls */}
            <nav className="flex justify-content-end mt-5 mx-2" aria-label="Page navigation">
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(1)}>
                            <i className="tf-icon ri-skip-back-mini-line ri-20px" />
                        </button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                        </button>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                            <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
                        </button>
                    </li>
                </ul>
            </nav>

            {/* Reject Modal */}
            {showRejectModal && (
                <div
                    className="modal fade show"
                    id="rejectAppointmentModal"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-simple modal-dialog-centered">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={closeRejectModal}
                            />
                            <div className="modal-body p-0">
                                <div className="text-center mb-6">
                                    <h2 className="mb-2">Are you sure!</h2>
                                </div>
                                <p className="mb-5 text-center">
                                    Are you sure you want to reject this appointment?
                                    <br /> Please confirm your decision to proceed with the rejection process.
                                </p>
                                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    {isLoading ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled
                                        >
                                            Please wait...
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={handleRejectButtonClick}
                                        >
                                            Reject
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        aria-label="Close"
                                        onClick={closeRejectModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* complete appointment */}
            {showCompleteModel && (
                <div
                    className="modal fade show"
                    id="completeAppointmentModal"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-simple modal-dialog-centered">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={closeCompleteModal}
                            />
                            <div className="modal-body p-0">
                                <div className="text-center mb-6">
                                    <h2 className="mb-2">Are you sure!</h2>
                                </div>
                                <p className="mb-5 text-center">
                                    Are you sure you want to complete this appointment?
                                    <br /> Please confirm your decision to proceed with the completion process.
                                </p>
                                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    {isLoading ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled
                                        >
                                            Please wait...
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={handleCompleteButtonClick}
                                        >
                                            Completed
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        aria-label="Close"
                                        onClick={closeCompleteModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Approve Modal */}
            {showApproveModal && (
                <div
                    className="modal fade show"
                    id="approveAppointmentModal"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ display: 'block' }}
                >
                    <div className="modal-dialog modal-simple modal-dialog-centered">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={closeApproveModal}
                            />
                            <div className="modal-body p-0">
                                <div className="text-center mb-6">
                                    <h2 className="mb-2">Are you sure!</h2>
                                </div>
                                <p className="mb-5 text-center">
                                    Are you sure you want to approve this appointment?
                                    <br /> Please confirm your decision to proceed with the approval process.
                                </p>
                                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    {isLoading ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled
                                        >
                                            Please wait...
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={handleApproveButtonClick}
                                        >
                                            Approve
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        aria-label="Close"
                                        onClick={closeApproveModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
