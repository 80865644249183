import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/utils/loggedin';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

export default function MyPrescriptions() {
    const [patients, setPatients] = useState([]);
    const { token } = useAuth()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const pages = 10;

    const [totalItems, setTotalItems] = useState(0);

    const fetchDoctorPrescriptions = async (page = 1) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/prescription/all?page=${page}&limit=${limit}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`,
                },
            });

            // Assuming response.data.prescriptions contains your prescriptions data
            const prescriptions = response.data.prescriptions.map((prescription) => ({
                id: prescription.id,
                name: `${prescription.patient.first_name} ${prescription.patient.last_name}`, // Combining first and last name
                email: prescription.patient.email,
                pres_url: prescription.pres_url,
            }));

            setPatients(prescriptions);
            setCurrentPage(page);
            setTotalPages(response?.data?.pagination.pageSize);
            setTotalItems(response?.data?.pagination?.totalItems);
        } catch (error) {

            toast.error(error?.response?.data?.message)
        }
    };

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
        const pageNumbers = [];
        const maxPageButtons = 5; // The number of visible page buttons
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        // Generate page number buttons
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* Display first page and ellipsis if necessary */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page numbers */}
                {pageNumbers}

                {/* Display last page and ellipsis if necessary */}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchDoctorPrescriptions(page);
    };

    useEffect(() => {
        fetchDoctorPrescriptions(); // Fetch prescriptions on component mount
    }, [limit, token]);

    return (
        <>
            <Helmet>
                <title>My Prescriptions - BharateClinics </title>
            </Helmet>

            <div className="card mt-10">
                <h5 className="card-header text-md-start text-center">
                    Prescriptions
                </h5>
                <div className="card-datatable text-nowrap">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        Show{" "}
                                        <select
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                className="datatables-ajax table table-bordered dataTable no-footer"
                                id="DataTables_Table_0"
                                aria-describedby="DataTables_Table_0_info"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="DataTables_Table_0"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Serial No: activate to sort column ascending"
                                            style={{ width: "10px" }}
                                        >
                                            #
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="DataTables_Table_0"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient Name: activate to sort column ascending"
                                            style={{ width: "185.2px" }}
                                        >
                                            Patient Name
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="DataTables_Table_0"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Email: activate to sort column ascending"
                                            style={{ width: "122.988px" }}
                                        >
                                            Email
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="DataTables_Table_0"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Download: activate to sort column ascending"
                                            style={{ width: "135.775px" }}
                                        >
                                            Prescription
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patients.length > 0 ? (
                                        patients.map((patient, index) => (
                                            <tr key={patient.id}>
                                                <td>{(currentPage - 1) * pages + index + 1}</td>
                                                <td>{patient.name}</td>
                                                <td>{patient.email}</td>
                                                <td>
                                                    {
                                                        patient?.pres_url?.length === 0 ?
                                                            "No prescription" :
                                                            <div className='mt-3'>
                                                                <Link to={patient?.pres_url} target="_blank" className="badge bg-lighter rounded-3">
                                                                    <img src="/img/icons/misc/pdf.png" alt="img" width="15" className="me-2" />
                                                                    <span className="h6 mb-0 text-body">Download </span>
                                                                </Link>
                                                            </div>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="odd">
                                            <td valign="top" colSpan={4} className="dataTables_empty">
                                                No data available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className="row mt-3">
                                <nav aria-label="Page navigation">
                                    {renderPagination()}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
