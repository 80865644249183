import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';

export default function ViewPrescription() {
    const location = useLocation();
    const navigate = useNavigate()
    const { token, doctorData } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")
    const [appointments, setAppointments] = useState({})
    const [remarks, setRemarks] = useState("");
    const [spo2, setSpo2] = useState("");
    const [bpSystolic, setBpSystolic] = useState('');
    const [bpDiastolic, setBpDiastolic] = useState('');
    const [bloodSugar, setBloodSugar] = useState('');
    const [heartRate, setHeartRate] = useState('');
    const [temperature, setTemperature] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [consultantType, setConsultantType] = useState('');
    const [aadhaarCardNumber, setAadhaarCardNumber] = useState(''); // State for Aadhaar card number
    const [aadhaarError, setAadhaarError] = useState(''); // 
    console.log(aadhaarCardNumber);
    const { user_id } = location.state || {};
    useEffect(() => {
        // Ensure location.state exists before destructuring adminId
        if (location.state && location.state.user_id) {
            fetchAppointments(user_id); // Pass the adminId to your data fetching function if necessary
            console.log("user", user_id)

            // console.log("User id is ", user_id)
        } else {
            // Navigate back if adminId is not present
            navigate("/patients");
        }
    }, [location.state, navigate]);


    useEffect(() => {
        const fetchUserData = async () => {
            if (user_id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/getuserbyid/${user_id}`, {
                        headers: {
                            "Signature": process.env.REACT_APP_API_SIGNATURE
                        }
                    });
                    setAadhaarCardNumber(response.data?.user_details?.aadhaar_card_number);
                } catch (error) {
                    toast.error(error?.response?.data?.message || "Failed to fetch user data");
                }
            }
        };
        fetchUserData();
    }, [appointments.user_id]);

    const fetchAppointments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/appointments/doctor`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Signature": process.env.REACT_APP_API_SIGNATURE
                }
            });
            setAppointments(response.data);
            // setAadhaarCardNumber(response.data?.User?.user_detail?.aadhaar_card_number);

        } catch (error) {
            setError(error);
            toast.error(error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const CreateDoctorPrescription = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/prescription/create`, {
                remarks,
                spo2,
                bp_systolic: bpSystolic,
                bp_diastolic: bpDiastolic,
                blood_sugar: bloodSugar,
                heart_rate: heartRate,
                temperature,
                weight,
                height,
                aadhaar_card_number: aadhaarCardNumber,
                consultant_type: consultantType

            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            toast.success("Prescription created successfully");
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || "Error creating prescription");

        }
    }

    // Validate Aadhaar card number
    const handleAadhaarChange = (e) => {
        const value = e.target.value;
        setAadhaarCardNumber(value);

        if (value.length !== 12) {
            setAadhaarError("Aadhaar card number must be 12 digits.");
        } else {
            setAadhaarError("");
        }
    }

    const handleConsultantTypeChange = (e) => {
        setConsultantType(e.target.value);
    };
    return (
        <div className="card mt-10 prescriptioncard ">
            <div className="card-body ">
                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                    <div>
                        <img src="../img/logo.png" height={100} alt="Logo" />
                        <h4>Bharat eClinics</h4>
                    </div>
                    <div>
                        <p className='mb-1'>
                            Doctor: <span className='fw-semibold'>{doctorData?.first_name + " " + doctorData?.last_name}</span> <br />
                            Email: <span className='fw-semibold'>{doctorData?.email}</span> <br />
                            Phone: <span className='fw-semibold'>{doctorData?.phone}</span>
                        </p>
                    </div>
                </div>

                <div className="divider divider-primary mt-3">
                    <div className="divider-text">Prescription</div>
                </div>

                <div className="row g-3 mt-4">
                    <div className="col-md-4">
                        <h4>Patient Details:</h4>
                        <h5>Aadhaar Card Number:</h5>
                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingAadhaar"
                                placeholder="Aadhaar Card Number"
                                value={aadhaarCardNumber}
                                onChange={handleAadhaarChange}
                            />
                            <label htmlFor="floatingAadhaar">Aadhaar Card Number</label>
                        </div>
                        {aadhaarError && <p className="text-danger">{aadhaarError}</p>}

                        <h5>Consultant Type:</h5>
                        <div className="form-floating form-floating-outline mb-3">
                            <select
                                className="form-control"
                                id="floatingConsultantType"
                                value={consultantType}
                                onChange={handleConsultantTypeChange}
                            >
                                <option value="">Select Consultant Type</option>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                            </select>
                            <label htmlFor="floatingConsultantType">Consultant Type</label>
                        </div>

                        <h4>Patient Reports:</h4>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingBloodPressure"
                                placeholder="120/80"
                                value={`${bpSystolic}/${bpDiastolic}`}
                                onChange={(e) => {
                                    const [systolic, diastolic] = e.target.value.split('/');
                                    setBpSystolic(Number(systolic));
                                    setBpDiastolic(Number(diastolic));
                                }}
                            />
                            <label htmlFor="floatingBloodPressure">Blood Pressure (Systolic/Diastolic)</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingBloodSugar"
                                placeholder="Blood Sugar (mg/dl)"
                                value={bloodSugar}
                                onChange={(e) => setBloodSugar(Number(e.target.value))}
                            />
                            <label htmlFor="floatingBloodSugar">Blood Sugar (mg/dl)</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingWeight"
                                placeholder="Weight (kg)"
                                value={weight}
                                onChange={(e) => setWeight(Number(e.target.value))}
                            />
                            <label htmlFor="floatingWeight">Weight (kg)</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingHeight"
                                placeholder="Height (cm)"
                                value={height}
                                onChange={(e) => setHeight(Number(e.target.value))}
                            />
                            <label htmlFor="floatingHeight">Height (cm)</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingTemperature"
                                placeholder="Temperature (°F)"
                                value={temperature}
                                onChange={(e) => setTemperature(Number(e.target.value))}
                            />
                            <label htmlFor="floatingTemperature">Temperature (°F)</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingHeartRate"
                                placeholder="Heart Rate (bpm)"
                                value={heartRate}
                                onChange={(e) => setHeartRate(Number(e.target.value))}
                            />
                            <label htmlFor="floatingHeartRate">Heart Rate (bpm)</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingSpo2"
                                placeholder="SPO2 (%)"
                                value={spo2}
                                onChange={(e) => setSpo2(Number(e.target.value))}
                            />
                            <label htmlFor="floatingSpo2">SPO2 (%)</label>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <img className='m-4' height={50} width={50} src="../img/rxicon.png" alt="Rx Icon" />
                        <textarea
                            className='w-100 p-2'
                            rows={20}
                            placeholder='Remarks'
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <button
                        onClick={CreateDoctorPrescription}
                        className="btn btn-primary btn-lg"
                    >
                        Submit Prescription
                    </button>
                </div>
            </div>
        </div>


    )
}
