import './App.css';
import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/utils/loggedin';
import Login from './pages/auth/login';
import Profile from './pages/profile';
import Dashboard from './pages/dashboard';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import VerifyOTP from './pages/appointments/verifyotp';
import Appointments from './pages/appointments/index';
import Test from './pages/test';
import Videopage from './components/services/videopage';
import Prescription from './pages/prescription';
// import Register from './pages/auth/register';
import VerifyDoctorOTP from './pages/auth/verifyotp';
import ViewAppointments from './pages/appointments/profile';
import ResetPasswordModal from './pages/auth/resetpassword';
import AllPatients from './pages/allpatients';
import Layout from './components/layout';
import Transaction from './pages/transaction';
import MyPresccriptions from './pages/myprescriptions';
import MeetingHistory from './pages/meeting';
import ViewPrescription from './pages/allpatients/viewprescription';


// const AppContent = () => {
//   const { isAuthenticated } = useAuth();

//   useEffect(() => {
//     // This effect will run whenever isAuthenticated changes
//   }, [isAuthenticated]);

//   return (
//     <Routes>
//       {isAuthenticated ? (
//         <>
//           <Route path="/" element={<Header><Dashboard /></Header>} />
//           <Route path="/profile" element={<Header><Profile /></Header>} />
//           <Route path="/appointments" element={<Header><Appointments /></Header>} />
//           <Route path="/appointments/view" element={<Header><ViewAppointments /></Header>} />
//           <Route path="/meeting" element={<Videopage />} />
//           <Route path="/allpatient" element={<Header><AllPatients /></Header>} />
//           <Route path="/verifyotp" element={<Header><VerifyOTP /></Header>} />
//           <Route path="/prescription" element={<Header><Prescription /></Header>} />
//           <Route path="/test" element={<Header><Test /></Header>} />
//           <Route path="*" element={<Header><h1 className='mt-10'>Page not found 404</h1></Header>} />
//         </>
//       ) : (
//         <>
//           <Route path="/login" element={<Login />} />
//           <Route path="/meeting" element={<Videopage />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/resetpassword" element={<ResetPasswordModal />} />
//           <Route path="/verifydoctorotp" element={<VerifyDoctorOTP />} />

//           <Route path="*" element={<Navigate to="/login" replace />} />
//         </>
//       )}
//     </Routes>
//   );
// };

// const App = () => {
//   return (
//     <AuthProvider>
//       <AppContent />
//       <ToastContainer />
//     </AuthProvider>
//   );
// };

// export default App;


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Or a proper loading spinner
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Layout>{children}</Layout>;
};

const App = () => {
  const { isAuthenticated, loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
        <Route path="/meeting" element={<Videopage />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/resetpassword" element={<ResetPasswordModal />} />
        <Route path="/verifydoctorotp" element={<VerifyDoctorOTP />} />
        <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path='/teams' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/appointments" element={<ProtectedRoute><Appointments /></ProtectedRoute>} />
        <Route path="/appointments/view" element={<ProtectedRoute><ViewAppointments /></ProtectedRoute>} />
        <Route path="/meeting" element={<Videopage />} />
        <Route path='/myprescriptions' element={<ProtectedRoute><MyPresccriptions /></ProtectedRoute>} />
        <Route path='/meetinghistory' element={<ProtectedRoute><MeetingHistory /></ProtectedRoute>} />
        <Route path="/patients" element={<ProtectedRoute><AllPatients /></ProtectedRoute>} />
        <Route path='/patients/prescription' element={<ProtectedRoute><ViewPrescription /></ProtectedRoute>} />
        <Route path='/transactions' element={<ProtectedRoute><Transaction /></ProtectedRoute>} />
        <Route path="/verifyotp" element={<ProtectedRoute><VerifyOTP /></ProtectedRoute>} />
        <Route path="/prescription" element={<ProtectedRoute><Prescription /></ProtectedRoute>} />
        <Route path="/test" element={<ProtectedRoute><Test /></ProtectedRoute>} />
        <Route path="*" element={<ProtectedRoute><h1 className='m-10 p-10'>Page not found 404</h1></ProtectedRoute>} />
      </Routes>
      <ToastContainer />
    </AuthProvider>
  );
};

export default App;