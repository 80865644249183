import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { useNavigate } from 'react-router-dom';
import IncomingCall from '../../helper/incomingcallmodel';
import ShipmentStatisticsChart from './test'
import { Helmet } from 'react-helmet';
import { tab } from '@testing-library/user-event/dist/tab';
import UpcomingAppointments from './upcomingappointments';
import AppointmentData from './appointmentdata';

export default function Dashboard() {
  const { token } = useAuth();
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!token) return;

    axios.get(`${process.env.REACT_APP_API_URL}/doctor/get-appointments-statistics`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Signature": process.env.REACT_APP_API_SIGNATURE
      }
    })
      .then(response => {
        setAppointmentsData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching appointmentsData:', error);
        setLoading(false);
      });
  }, [token]);

  if (!token || loading) {
    return <div>Loading...</div>; // Show a loading state or redirect to login if not authenticated
  }

  return (
    <>
      <Helmet>
        <title>Dashboard Page</title>
      </Helmet>
      <div className="row g-6 mt-4 mb-6">

        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-primary h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-primary">
                    <i className="menu-icon tf-icons ri-group-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{appointmentsData?.total_appointments}</h4>
              </div>
              <h6 className="mb-0 fw-normal">All Appointments</h6>
              <p className="mb-0">
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-warning h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-warning">
                    <i className="menu-icon tf-icons ri-calendar-check-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{appointmentsData?.upcoming_appointments}</h4>
              </div>
              <h6 className="mb-0 fw-normal">Upcoming Appointments</h6>
              <p className="mb-0">
                {/* <span className="me-1 fw-medium">-8.7%</span>
                <small className="text-muted">than last week</small> */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-danger h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-danger">
                    <i className="menu-icon tf-icons ri-close-circle-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{appointmentsData?.rejected_appointments}</h4>
              </div>
              <h6 className="mb-0 fw-normal">Rejected Appointments</h6>
              <p className="mb-0">
                {/* <span className="me-1 fw-medium">+4.3%</span>
                <small className="text-muted">than last week</small> */}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="card card-border-shadow-success h-100">
            <div className="card-body">
              <div className="d-flex align-items-center mb-2">
                <div className="avatar me-4">
                  <span className="avatar-initial rounded-3 bg-label-success">
                    <i className="menu-icon tf-icons ri-checkbox-circle-line"></i>

                  </span>
                </div>
                <h4 className="mb-0">{appointmentsData?.completed_appointments}</h4>
              </div>
              <h6 className="mb-0 fw-normal">Completed Appointment</h6>
              <p className="mb-0">
                {/* <span className="me-1 fw-medium">-2.5%</span>
                <small className="text-muted">than last week</small> */}
              </p>
            </div>
          </div>
        </div>


        <AppointmentData appointmentData={{ completed_appointments: appointmentsData?.completed_appointments, total_appointments: appointmentsData?.total_appointments, rejected_appointments: appointmentsData?.rejected_appointments, upcoming_appointments: appointmentsData?.upcoming_appointments }} />
        <UpcomingAppointments />
        {/*/ Card Border Shadow */}

      </div>
      {/* <ShipmentStatisticsChart /> */}

    </>
  );
}
