import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function ResetPasswordByEmail({ show, handleClose }) {
    const [email, setEmail] = useState(''); // State to store user email input
    const [otp, setOtp] = useState(''); // State to store OTP input
    const [password, setPassword] = useState(''); // State to store new password
    const [showOtpModal, setShowOtpModal] = useState(false); // State to show/hide OTP modal
    const [resendTimer, setResendTimer] = useState(0); // Timer for resend
    const [resendDisabled, setResendDisabled] = useState(false); // Flag for resend button

    const handleChangeOtp = (e, index) => {
        const value = e.target.value;
        if (isNaN(value)) return; // Prevent non-numeric input

        const otpArray = otp.split(''); // Ensure otp is a string
        otpArray[index] = value;

        // Combine the array back into a string
        setOtp(otpArray.join(''));

        // Focus on next input
        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.error('Please enter your email address.');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/doctor/password/reset/send`,
                { email }, // Sending the email as payload
                {
                    headers: {
                        'Signature': process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Password reset email sent successfully.');
                setShowOtpModal(true); // Show the OTP modal
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error resetting password');
        }
    };



    const handleOtpSubmit = async (e) => {
        e.preventDefault();

        if (otp.length !== 6 || !password) {
            toast.error('Please enter a valid 6-digit OTP and new password.');
            return;
        }

        try {
            // Simulate OTP verification and password reset
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/doctor/password/reset/verify`,
                { email, otp, password },
                {
                    headers: {
                        'Signature': process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Password updated successfully.');
                handleClose();
                setShowOtpModal(false);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error updating password');
        }
    };

    useEffect(() => {
        if (resendTimer > 0) {
            const timer = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer); // Cleanup timer on unmount
        } else {
            setResendDisabled(false); // Re-enable resend button when timer reaches 0
        }
    }, [resendTimer]);

    return (
        <div>
            {/* Password Reset Modal */}
            <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex={-1} role="dialog" style={show ? { backgroundColor: 'rgba(0,0,0,0.5)' } : {}}>
                <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close" />
                        <div className="modal-body p-0">
                            <div className="text-center mb-6">
                                <h4 className="mb-2">Reset Password</h4>
                                <p>Enter your email address to receive a password reset link.</p>
                            </div>
                            <form id="resetPasswordForm" className="row g-5" onSubmit={(e) => handleSubmit(e)}>
                                <div className="col-12">
                                    <div className="input-group input-group-merge">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="email"
                                                id="modalResetPasswordEmail"
                                                name="modalResetPasswordEmail"
                                                className="form-control"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)} // Capture user input
                                            />
                                            <label htmlFor="modalResetPasswordEmail">Email Address</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={handleClose} aria-label="Close">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* OTP Modal */}
            <div className={`modal fade ${showOtpModal ? 'show d-block' : ''}`} tabIndex={-1} role="dialog" style={showOtpModal ? { backgroundColor: 'rgba(0,0,0,0.5)' } : {}}>
                <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close" onClick={() => setShowOtpModal(false)} aria-label="Close" />
                        <div className="card-body mt-1">
                            <h4 className="mb-1">Verification of the user 💬</h4>
                            <p className="text-start mb-5">
                                We sent a verification code to your mobile. Enter the code from
                                the mobile in the field below.
                                <span className="d-block mt-1 h6">Email</span>
                            </p>
                            <p className="mb-0">Type your 6-digit security code</p>
                            <form id="twoStepsForm" onSubmit={(e) => handleOtpSubmit(e)}>
                                <div className="mb-3">
                                    <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                                        {[...Array(6)].map((_, index) => (
                                            <input
                                                key={index}
                                                type="tel"
                                                className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                                                maxLength={1}
                                                value={otp[index] || ''}
                                                onChange={(e) => handleChangeOtp(e, index)}
                                                onFocus={(e) => e.target.select()}
                                                autoFocus={index === 0}
                                            />
                                        ))}
                                    </div>
                                    <div className="mt-4">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type="password"
                                                id="modalNewPassword"
                                                name="modalNewPassword"
                                                className="form-control"
                                                placeholder="Enter new password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)} // Capture new password input
                                            />
                                            <label htmlFor="modalNewPassword">New Password</label>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary d-grid w-100 mb-5" type="submit">Verify and Set New Password</button>
                                <div className="text-center">
                                    Didn't get the code?
                                    {resendTimer > 0 ? (
                                        <span style={{ color: "blue", padding: "2px" }}>Resend in {resendTimer}s</span>
                                    ) : (
                                        <a href="javascript:void(0);" onClick={(e) => handleSubmit(e)}>Resend</a>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
