import React from 'react'

export default function Test() {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div style={{ width: '50rem'}} className='shadow m-6 p-5 bg-white'>
        <h2>Bharat e Clinics</h2>
        <div class="divider divider-primary">
          <div class="divider-text">Prescription</div>
        </div>
        <div className="row gap-3">
          <div className="border col-4">
            <h4>Patient Reports:</h4>
            <p>Blood Pressure: 120/80</p>
            <p>Blood Sugar: 120mg/dl</p>
            <p>Weight: 60kg</p>
            <p>Temperature: 37.5°C</p>
            <p>Pulse: 70bpm</p>
            <p>Respiratory Rate: 20/min</p>
          </div>
          <div className="border col-7">
            <img className='m-2' height={50} width={50} src="https://w7.pngwing.com/pngs/817/921/png-transparent-medical-prescription-pharmacy-logo-prescription-s-text-monochrome-pharmaceutical-drug-thumbnail.png" alt="" />
            <textarea className='w-100 p-2' rows={20} name="" id=""></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}
