import { Link } from 'react-router-dom'
import AllAppointments from './allappointments'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

export default function Appointments() {
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/appointments/doctor`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                // Ensure appointments data exists and is an array
                setAppointments(response.data || []);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                toast.error(error?.response?.data?.message || "An error occurred");
            });
    }, [token]);

    // Ensure `appointments` is always an array and doesn't cause errors
    const totalAppointments = appointments?.length || 0;
    const rejectedAppointments = appointments?.filter(appointment => appointment.status === 'rejected').length || 0;
    const completedAppointments = appointments?.filter(appointment => appointment.status === 'completed').length || 0;
    const scheduledAppointments = appointments?.filter(appointment => appointment.status === 'scheduled').length || 0;

    return (
        <>
            <Helmet>
                <title>All Appointments - Doctor BharateClinics </title>
            </Helmet>

            <div className="row g-6 mb-6 mt-4">
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Total Appointments</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-2">{totalAppointments}</h4>
                                    </div>
                                    <small className="mb-0">All Appointments</small>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded-3">
                                        <div className="ri-group-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Rejected Appointments</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{rejectedAppointments}</h4>
                                    </div>
                                    <small className="mb-0">Rejected Appointments</small>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-danger rounded-3">
                                        <div className="ri-user-add-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Completed Appointments</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{completedAppointments}</h4>
                                    </div>
                                    <small className="mb-0">Completed Appointments</small>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-success rounded-3">
                                        <div className="ri-user-follow-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Scheduled Appointments</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{scheduledAppointments}</h4>
                                    </div>
                                    <small className="mb-0">Scheduled Appointments</small>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-warning rounded-3">
                                        <div className="ri-user-search-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Appointments List</h5>
                </div>
                <div className="card-datatable table-responsive">
                    <AllAppointments appointments={appointments} loading={loading} />
                </div>
            </div>
        </>
    );
}
