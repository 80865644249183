import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../components/utils/loggedin';
import { format } from 'date-fns'

export default function Transaction() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { token } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const fetchTransaction = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/transaction/doctor/history?page=${page}&limit=${limit}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            setTransactions(response.data.txn);
            setCurrentPage(page);
            setTotalPages(response?.data?.pagination.totalPages);
            setTotalItems(response?.data?.pagination?.totalItems);
        } catch (error) {
            console.log("Cannot able to fetch Doctor Transaction", error);
            setError("Failed to fetch transactions. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchTransaction(page);
    };

    const handleLimitChange = (e) => {
        const newLimit = parseInt(e.target.value, 10);
        setLimit(newLimit);
        setCurrentPage(1); // Reset to first page
    };

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const maxPageButtons = 5; // The number of visible page buttons
        const totalPagesCalculated = Math.ceil(totalItems / limit);

        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPagesCalculated);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination justify-content-center">
                {/* Previous Button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        aria-label="Previous"
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* First Page and Ellipsis */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page Numbers */}
                {pageNumbers}

                {/* Last Page and Ellipsis */}
                {endPage < totalPagesCalculated && (
                    <>
                        {endPage < totalPagesCalculated - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPagesCalculated)}>
                                {totalPagesCalculated}
                            </button>
                        </li>
                    </>
                )}

                {/* Next Button */}
                <li className={`page-item ${currentPage === totalPagesCalculated ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPagesCalculated}
                        aria-label="Next"
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        fetchTransaction(); // Fetch transactions on component mount and whenever limit changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        return `${day}/${month}/${year}`;
    };

    return (
        <div className="card mt-10">
            <div className="card-header border-bottom">
                <h5 className="card-title mb-0">Transactions</h5>
            </div>
            <div className="card-datatable table-responsive">
                <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                    {/* Entries Per Page Selector and Info */}
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    Show{" "}
                                    <select
                                        name="DataTables_Table_0_length"
                                        aria-controls="DataTables_Table_0"
                                        className="form-select form-select-sm"
                                        value={limit}
                                        onChange={handleLimitChange}
                                    >
                                        <option value={7}>7</option>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={75}>75</option>
                                        <option value={100}>100</option>
                                    </select>{" "}
                                    entries
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Loading Indicator */}
                    {loading ? (
                        <div className="text-center my-4">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* Transactions Table */}
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Transaction Id</th>
                                        <th>Patient Name</th>
                                        <th>Amount</th>

                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.length > 0 ? transactions.map((transaction, index) => (
                                        <tr key={transaction.id}>
                                            <td>{(currentPage - 1) * limit + index + 1}</td>
                                            <td>{transaction.razorpay_payment_id || " N / A"}</td>
                                            <td>{transaction.user?.first_name + " " + transaction.user?.last_name}</td>
                                            <td>₹ {transaction.amount}</td>

                                            <td>{transaction.status}</td>
                                            <td>
                                                {transaction.createdAt ? format(new Date(transaction.createdAt), 'MMMM dd, yyyy') : 'N/A'}
                                            </td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">No Data Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {/* Error Message */}
                            {error && (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            )}

                            {/* Pagination Controls */}
                            <div className="row mt-3">
                                <nav aria-label="Page navigation">
                                    {renderPagination()}
                                </nav>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
