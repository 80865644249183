import React from 'react';
import { Helmet } from 'react-helmet';

export default function Details({ appointment }) {
    return (
        <>
            <Helmet>
                <title>
                    Patient Details - Doctor BharateClinics
                </title>

            </Helmet>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Details</h5>
                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td>
                                    <span className="fw-medium">Patient Id</span>
                                </td>
                                <td>{appointment?.User?.username}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Patient Name</span>
                                </td>
                                <td>{appointment.User?.first_name + " " + appointment.User?.last_name}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Address</span>
                                </td>
                                <td>{appointment.User?.user_detail?.address}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">City</span>
                                </td>
                                <td>{appointment.User?.user_detail?.city}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Zip</span>
                                </td>
                                <td>{appointment.User?.user_detail?.zip}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
