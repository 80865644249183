import React, { useEffect, useRef } from 'react';

export default function IncomingCall({ callRequest, onAccept, onDecline }) {
    const audioRef = useRef(null);

    useEffect(() => {
        if (callRequest) {
            const audioElement = document.createElement('audio');

            // Create multiple sources for better compatibility

            const wavSource = document.createElement('source');
            wavSource.src = '/vendor/assets/calling-tone.wav';
            wavSource.type = 'audio/wav';



            audioElement.appendChild(wavSource);
            audioElement.loop = true; // Loop the music
            audioElement.play().catch((err) => console.error('Play error:', err));

            audioRef.current = audioElement;
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset audio to the beginning
                audioRef.current = null;
            }
        };
    }, [callRequest]);


    // Handle Accept and Decline actions
    const handleAccept = () => {
        if (audioRef.current) {
            audioRef.current.pause(); // Stop music
        }
        onAccept(); // Call the provided onAccept function
    };

    const handleDecline = () => {
        if (audioRef.current) {
            audioRef.current.pause(); // Stop music
        }
        onDecline(); // Call the provided onDecline function
    };

    return (
        callRequest ? (
            <div className="incoming-call-div">
                <div className="card">
                    <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title m-0 me-2">Incoming Call</h5>
                    </div>
                    <div className="card-body">
                        <small>
                            Incoming call from {callRequest.userData.first_name} {callRequest.userData.last_name}.
                        </small>
                        <div className="text-center mt-3">
                            <button
                                onClick={handleAccept}
                                className="btn btn-success me-2"
                            >
                                Accept
                            </button>
                            <button
                                onClick={handleDecline}
                                className="btn btn-danger"
                            >
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    );
}
