import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';
import ResetPasswordByEmail from './resetpasswordemail';

export default function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false); // State for showing the modal
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!identifier || !password) {
            toast.error('Phone/Username and password are required');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/doctor/login`,
                { identifier, password },
                {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE
                    }
                });
            const { doctor, token } = response.data;
            if (response.status === 200) {
                login(token);
                toast.success(`Welcome ${doctor.first_name + " " + doctor.last_name}`);
                navigate('/');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error logging in');
        }
    };

    const handleForgotPasswordClick = () => {
        setShowResetPasswordModal(true); // Show the reset password modal when clicked
    };

    const handleCloseResetPasswordModal = () => {
        setShowResetPasswordModal(false); // Close the modal
    };

    return (
        <div className="authentication-wrapper authentication-cover">
            <a href="/" className="auth-cover-brand d-flex align-items-center gap-2">
                <span className="app-brand-logo demo">
                    <span style={{ color: "var(--bs-primary)" }}>
                        <img height={80} src="./img/logo.png" alt="" />
                    </span>
                </span>
                <span className="app-brand-text demo text-heading fw-semibold">
                    Bharat eClinics
                </span>
            </a>

            <div className="authentication-inner row m-0">
                <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
                    <img
                        src="./img/illustrations/auth-login-illustration-light.png"
                        className="auth-cover-illustration w-100"
                        alt="auth-illustration"
                        data-app-light-img="illustrations/auth-login-illustration-light.png"
                        data-app-dark-img="illustrations/auth-login-illustration-dark.html"
                    />
                    <img
                        src="./img/illustrations/auth-cover-login-mask-light.png"
                        className="authentication-image"
                        alt="mask"
                        data-app-light-img="illustrations/auth-cover-login-mask-light.png"
                        data-app-dark-img="illustrations/auth-cover-login-mask-dark.html"
                    />
                </div>

                <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-12 px-12 py-6">
                    <div className="w-px-400 mx-auto pt-5 pt-lg-0">
                        <h4 className="mb-1">Welcome to Bharat eClinics! 👋</h4>
                        <p className="mb-5">
                            Please sign-in to your account
                        </p>
                        <form
                            id="formAuthentication"
                            className="mb-5"
                            onSubmit={(e) => handleSubmit(e)}
                        >
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="identifier"
                                    name="identifier"
                                    placeholder="Enter your username or phone number"
                                    value={identifier}
                                    onChange={(e) => setIdentifier(e.target.value)}
                                    autoFocus
                                />
                                <label htmlFor="identifier">Phone or Username</label>
                            </div>
                            <div className="mb-5">
                                <div className="form-password-toggle">
                                    <div className="input-group input-group-merge">
                                        <div className="form-floating form-floating-outline">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                className="form-control"
                                                name="password"
                                                placeholder="············"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                aria-describedby="password"
                                            />
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <span onClick={() => { setShowPassword(!showPassword) }} className="input-group-text cursor-pointer">
                                            <i className={showPassword ? "ri-eye-line" : "ri-eye-off-line"} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5 d-flex justify-content-between mt-5">
                                <div className="form-check mt-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="remember-me"
                                        checked={rememberMe}
                                        onChange={() => setRememberMe(!rememberMe)}
                                    />
                                    <label className="form-check-label" htmlFor="remember-me">
                                        Remember Me
                                    </label>
                                </div>
                                <span onClick={handleForgotPasswordClick} className="float-end mb-1 mt-2" style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}>
                                    Forgot Password?
                                </span>
                            </div>
                            <button className="btn btn-primary d-grid w-100">Sign in</button>
                        </form>

                    </div>
                </div>
            </div>

            {/* Reset Password Modal */}
            <ResetPasswordByEmail
                show={showResetPasswordModal}
                handleClose={handleCloseResetPasswordModal}
            />
        </div>
    );
}
