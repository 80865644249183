import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../components/utils/loggedin';

export default function ResetPasswordModal({ show, handleClose }) {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (newPassword.length < 8) {
            newErrors.newPassword = "Password must be at least 8 characters long.";
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/doctor/reset/password`, {
                email, // or identifier if you prefer
                password: newPassword, // Updated key to match backend expectation
            },
                {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                    }
                });

            if (response.status === 200) {
                toast.success("Password reset successfully");
                handleClose();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Error resetting password');
        }
    };

    if (!show) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <div style={styles.header}>
                    <h5>Reset Password</h5>
                    <button onClick={handleClose} style={styles.closeButton}>X</button>
                </div>
                <div style={styles.body}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div style={styles.formGroup}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                style={styles.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                id="newPassword"
                                style={{ ...styles.input, borderColor: errors.newPassword ? 'red' : '' }}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            {errors.newPassword && <div style={styles.errorText}>{errors.newPassword}</div>}
                        </div>
                        <div style={styles.formGroup}>
                            <label htmlFor="confirmPassword">Confirm New Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                style={{ ...styles.input, borderColor: errors.confirmPassword ? 'red' : '' }}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            {errors.confirmPassword && <div style={styles.errorText}>{errors.confirmPassword}</div>}
                        </div>
                        <button type="submit" style={styles.submitButton}>Reset Password</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    closeButton: {
        background: 'transparent',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
    },
    submitButton: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};
