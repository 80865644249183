import React, { useEffect, useState } from 'react';
import { useAuth } from '../../components/utils/loggedin';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function UpcomingAppointments() {
    const { token } = useAuth();
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [days, setDays] = useState(1);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        return `${day}/${month}/${year}`;
    };

    const fetchUpcomingAppointments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/upcoming-appointments?days=${days}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            setAppointments(response.data.appointments);  // Assuming response.data returns the appointment array
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUpcomingAppointments();
    }, [days]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="col-xxl-8">
            <div className="card h-100 overflow-auto">
                <div className="card-header d-flex justify-content-between">
                    <div>
                        <h5 className="card-title mb-1">Upcoming Appointments</h5>
                        <p className="card-subtitle mb-0">{days === 1 ? <>Showing Today's Appointment</> : <>Scheduled for Next {days} Days</>}</p>
                    </div>
                    <div className="dropdown">
                        <button
                            className="btn btn-text-secondary rounded-pill text-muted border-0 p-1 waves-effect waves-light"
                            type="button"
                            id="earningReportsTabsId"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="ri-more-2-line ri-20px" />
                        </button>
                        <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="earningReportsTabsId"
                        >
                            <button
                                className="dropdown-item waves-effect"
                                onClick={() => setDays(1)}
                            >
                                Today
                            </button>
                            <button
                                className="dropdown-item waves-effect"
                                onClick={() => setDays(7)}
                            >
                                Upcoming 7 Days
                            </button>
                        </div>
                    </div>
                </div>
                <div className="tab-content p-0">
                    <div className="tab-pane fade show active" id="navs-orders-id-1" role="tabpanel">
                        <div className="table-responsive text-nowrap">
                            <table className="table border-top">
                                <thead>
                                    <tr>
                                        <th className="bg-transparent border-bottom">Appointment Number</th>
                                        <th className="bg-transparent border-bottom">Date</th>
                                        <th className="bg-transparent border-bottom">Status</th>
                                        <th className="bg-transparent border-bottom">Description</th>
                                        <th className="bg-transparent border-bottom">Appointment Type</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {appointments.map(appointment => (
                                        <tr key={appointment.id}>
                                            <td>{appointment.appointment_id}</td>
                                            <td>{formatDate(appointment.date)}</td>
                                            <td className="text-end">
                                                <div className={`badge bg-label-${appointment.status === 'scheduled' ? 'success' : 'warning'} rounded-pill`}>
                                                    {appointment.status}
                                                </div>
                                            </td>
                                            <td>{appointment.description}</td>
                                            <td>{appointment.appointment_type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
