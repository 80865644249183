import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import axios from 'axios';
import { useAuth } from '../utils/loggedin';

export default function Videopage() {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  const myParam = params.get('roomID');
  const name = params.get('name') || 'Your Name'
  const roomID = myParam || 'xyzefg';
  const userID = randomMeetingID(6);


  useEffect(() => {
    const myMeeting = async (element) => {
      try {
        const appID = parseInt(process.env.REACT_APP_ZEGO_APP_ID);
        const serverSecret = process.env.REACT_APP_ZEGO_SERVER_SECRET;

        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, name);

        const zp = ZegoUIKitPrebuilt.create(kitToken);


        zp.joinRoom({
          showScreenSharingButton: false,
          lowerLeftNotification: {
            showTextChat: false,// Hide the text chat on the lower left.
          },
          container: element,
          sharedLinks: [
            {
              name: 'Share Meeting Link',
              url: `${window.location.protocol}//${window.location.host}${window.location.pathname}?roomID=${roomID}`,
            },
          ],
          scenario: {
            mode: ZegoUIKitPrebuilt.OneONoneCall,
          },
        });

        console.log("Joined meeting successfully:", { roomID, userID });
      } catch (error) {
        console.error("Error during Zego UIKit setup:", error);
      }
    };

    const container = document.getElementById('meetingContainer');
    if (container) myMeeting(container);
  }, [roomID, userID]);

  return (
    <div style={{ width: '100vw', height: '100vh', margin: 0, padding: 0 }}>
      <div id="meetingContainer" style={{ width: '100%', height: '100%' }} />
    </div>
  );
}


function randomMeetingID(len) {
  let result = '';
  const chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP';
  const maxPos = chars.length;
  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

