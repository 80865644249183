import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function DoctorPasswordForm({ appointment }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true); // Initialize loading state to true

    useEffect(() => {
        const fetchPrescriptionData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prescription/${appointment.user_id}`, {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE
                    }
                });
                console.log(response.data);
                setData(response.data);
            } catch (error) {
                console.error('There was an error fetching the data!', error);
                setData(null); // Ensure data is null on error
            } finally {
                setLoading(false); // Set loading to false after fetch
            }
        };
        fetchPrescriptionData();
    }, [appointment.user_id]);

    const {
        prescription = {},
        userDetails = {}
    } = data || {};

    const {
        Clinic = {}
    } = prescription;

    const {
        id,
        createdAt,
        remarks,
        spo2,
        bp_systolic,
        bp_diastolic,
        blood_sugar,
        heart_rate,
        temperature,
        weight,
        height,
        patient,
        pres_url
    } = prescription;

    const {
        age,
        gender
    } = userDetails;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);

        return `${day}/${month}/${year}`;
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className="container">
                <div className="shadow rounded p-3 p-md-5 bg-white border border-primary prescription-details w-100 w-md-75 mx-auto">
                    {loading ? (
                        <div className="text-center">
                            <p className="text-success font-weight-bold">Please wait...</p>
                        </div>
                    ) : !prescription || Object.keys(prescription).length === 0 ? (
                        <div className="text-center">
                            <p className="text-danger font-weight-bold">No prescription data found</p>
                        </div>
                    ) : (
                        <>
                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                                <div className="text-center text-md-left mb-3 mb-md-0">
                                    <img
                                        src="/img/logo.png"
                                        className="img-fluid"
                                        alt="Logo"
                                        style={{ maxHeight: "150px" }}
                                    />
                                    <h4 className="mt-2">Bharat eClinics</h4>
                                </div>

                                <div className="text-start">
                                    {Clinic && (
                                        <div className="row">
                                            <div className="col-4 text-start">
                                                <p className="mb-1 fw-bold">Clinic:</p>
                                                <p className="mb-1 fw-bold">Email:</p>
                                                <p className="mb-1 fw-bold">Phone:</p>
                                                <p className="mb-1 fw-bold">Address:</p>
                                                <p className="mb-1 fw-bold">City:</p>
                                                <p className="mb-1 fw-bold">State:</p>
                                            </div>

                                            <div className="col-8 text-start">
                                                <p className="mb-1">
                                                    {Clinic?.first_name + " " + Clinic?.last_name}
                                                </p>
                                                <p className="mb-1">{Clinic?.email}</p>
                                                <p className="mb-1">{Clinic?.phone}</p>
                                                <p className="mb-1">{Clinic?.address}</p>
                                                <p className="mb-1">{Clinic?.city}</p>
                                                <p className="mb-1">{Clinic?.state}</p>
                                            </div>
                                        </div>
                                    )}

                                    {prescription && (
                                        <div className="mt-3">
                                            <Link
                                                to={pres_url}
                                                target="_blank"
                                                className="badge bg-lighter rounded-3"
                                            >
                                                <img
                                                    src="/img/icons/misc/pdf.png"
                                                    alt="img"
                                                    width="15"
                                                    className="me-2"
                                                />
                                                <span className="h6 mb-0 text-body">Download Pdf</span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="divider divider-primary mt-4">
                                <div className="divider-text">Prescription</div>
                            </div>

                            <div className="table-responsive mt-3">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="text-center">
                                                Patient Details
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>{`${patient?.first_name} ${patient?.last_name}`}</td>
                                        </tr>
                                        <tr>
                                            <td>Age</td>
                                            <td>{age} years</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td>{gender}</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>{formatDate(createdAt)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mt-3">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="text-center">
                                                Patient Reports
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Blood Pressure</td>
                                            <td>
                                                {bp_systolic}/{bp_diastolic}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Blood Sugar</td>
                                            <td>{blood_sugar} mg/dl</td>
                                        </tr>
                                        <tr>
                                            <td>Weight</td>
                                            <td>{weight} kg</td>
                                        </tr>
                                        <tr>
                                            <td>Temperature</td>
                                            <td>{temperature} °C</td>
                                        </tr>
                                        <tr>
                                            <td>Pulse</td>
                                            <td>{heart_rate} bpm</td>
                                        </tr>
                                        <tr>
                                            <td>Respiratory Rate</td>
                                            <td>{spo2}%</td>
                                        </tr>
                                        <tr>
                                            <td>Heart Rate</td>
                                            <td>{heart_rate}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
