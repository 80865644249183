import React from 'react'
import { Helmet } from 'react-helmet';

export default function Appointment({ appointment }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

        return `${day}/${month}/${year}`;
    };

    const formatTime = (timeString) => {
        // Assume timeString is in 'HH:mm:ss' format
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    return (
        <>

            <Helmet>
                <title>
                    Patient Appointment Details - Doctor BharateClinics
                </title>

            </Helmet>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Appointment Details</h5>
                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td>
                                    <span className="fw-medium">Appointment Number</span>
                                </td>
                                <td>{appointment.appointment_id}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Status</span>
                                </td>
                                <td>{appointment.status}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Date</span>
                                </td>
                                <td>{formatDate(appointment.date)}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Time Slot</span>
                                </td>
                                <td>{formatTime(appointment.arrival_time)} - {formatTime(appointment.departure_time)}</td>
                            </tr>

                            <tr>
                                <td>
                                    <span className="fw-medium">Description</span>
                                </td>
                                <td>{appointment.description}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Appointment Type</span>
                                </td>
                                <td>{appointment.appointment_type}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Problem</span>
                                </td>
                                <td>{appointment.problem}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Booked On</span>
                                </td>
                                <td>{formatDate(appointment.created_at)}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>


        </>
    )
}
