import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../components/utils/loggedin';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import 'react-quill/dist/quill.snow.css';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropimg';

export default function Profile() {
    const editorRef = useRef(null)
    const { doctorData, token, fetchDoctorData } = useAuth();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('profile');
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLocating, setIsLocating] = useState(false);
    const [locationError, setLocationError] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [imageSrc, setImageSrc] = useState(null); // To display the image in the cropper
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [location, setLocation] = useState({
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        longitude: "",
        latitude: "",
        status: ""
    });
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

        return `${day}/${month}/${year}`;
    };

    // Initialize form data based on doctorData
    // Initial state setup

    const fetchLocationDetailsByPinCode = async (pincode) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/${pincode}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                }
            });

            if (response.data && response.data.length > 0) {
                const locationData = response.data[0];

                // Map the fields from API response to your state
                formData(prevState => ({
                    ...prevState,
                    address: locationData.street || "",
                    city: locationData.city || "",
                    state: locationData.state || "",
                    zip: locationData.postalCode || "",
                    latitude: locationData.latitude || "",
                    longitude: locationData.longitude || "",

                }));
            } else {
                toast.warning("No valid location data found for the given pincode.");
            }
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: '',
        about: '',
        doctorType: '',
        address: '',
        city: '',
        state: '',
        mci_number: '',
        zip: '',
        experience: [
            {
                hospital: '',
                position: '',
                startDate: '',
                endDate: ''
            }
        ],
        qualification: [
            {
                university: '',
                qualification: '',
                startDate: '',
                endDate: ''
            }
        ],
        specializations: [
            {
                specialization: '',
                startDate: '',
                endDate: ''
            }
        ],
        time_slots: [
            { day: '', startTime: '', endTime: '' } // Initial empty time slot
        ],
        latitude: '',
        longitude: ''
    });
    // Handler to update experience fields
    const handleExperienceChange = (index, e) => {
        const { name, value } = e.target;
        const updatedExperiences = formData.experience.map((exp, i) =>
            i === index ? { ...exp, [name]: value } : exp
        );
        setFormData({ ...formData, experience: updatedExperiences });
    };


    // Handler to add a new experience entry
    const handleAddExperience = () => {
        setFormData({
            ...formData,
            experience: [
                ...formData.experience,
                { hospital: '', position: '', startDate: '', endDate: '' }
            ]
        });
    };

    // Handler to remove an experience entry
    const handleRemoveExperience = (index) => {
        const updatedExperiences = formData.experience.filter((_, i) => i !== index);
        setFormData({ ...formData, experience: updatedExperiences });
    };

    const handleQualificationChange = (index, e) => {
        const { name, value } = e.target;
        const updatedQualifications = formData.qualification.map((qual, i) =>
            i === index ? { ...qual, [name]: value } : qual
        );
        setFormData({ ...formData, qualification: updatedQualifications });
    };

    const handleAddQualification = () => {
        setFormData({
            ...formData,
            qualification: [
                ...formData.qualification,
                { university: '', qualification: '', startDate: '', endDate: '' }
            ]
        });
    };

    const handleRemoveQualification = (index) => {
        const updatedQualifications = formData.qualification.filter((_, i) => i !== index);
        setFormData({ ...formData, qualification: updatedQualifications });
    };

    const handleSpecializationChange = (index, e) => {
        const { name, value } = e.target;
        const updatedSpecializations = formData.specializations.map((spec, i) =>
            i === index ? { ...spec, [name]: value } : spec
        );
        setFormData({ ...formData, specializations: updatedSpecializations });
    };

    const handleAddSpecialization = () => {
        setFormData({
            ...formData,
            specializations: [
                ...formData.specializations,
                { specialization: '', startDate: '', endDate: '' }
            ]
        });
    };

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = formData.specializations.filter((_, i) => i !== index);
        setFormData({ ...formData, specializations: updatedSpecializations });
    };

    // Handle time slot changes
    const handleTimeSlotChange = (e, index, field) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            time_slots: prevState.time_slots.map((slot, i) =>
                i === index ? { ...slot, [field]: value } : slot
            ),
        }));
    };


    // Add a new empty time slot
    const addTimeSlot = () => {
        setFormData(prevState => ({
            ...prevState,
            time_slots: [...prevState.time_slots, { day: '', startTime: '', endTime: '' }]
        }));
    };


    const removeTimeSlot = (index) => {
        setFormData(prevState => ({
            ...prevState,
            time_slots: prevState.time_slots.filter((_, i) => i !== index)
        }));
    };


    useEffect(() => {
        if (doctorData) {
            setFormData({
                firstName: doctorData.first_name || '',
                lastName: doctorData.last_name || '',
                email: doctorData.email || '',
                phone: doctorData.phone || '',
                doctorType: doctorData.doctor_type || '',
                gender: doctorData.gender || '',
                about: doctorData.about || '',
                address: doctorData.address || '',
                price: doctorData.price || '',
                city: doctorData.city || '',
                mci_number: doctorData.mci_number || '',
                state: doctorData.state || '',
                zip: doctorData.zip || '',
                experience: doctorData.experience && Array.isArray(doctorData.experience)
                    ? doctorData.experience.map(exp => ({
                        hospital: exp.hospital || '',
                        position: exp.position || '',
                        startDate: exp.startDate || '',
                        endDate: exp.endDate || ''
                    }))
                    : [{ hospital: '', position: '', startDate: '', endDate: '' }],
                qualification: doctorData.qualification && Array.isArray(doctorData.qualification)
                    ? doctorData.qualification.map(qual => ({
                        university: qual.university || '',
                        qualification: qual.qualification || '',
                        startDate: qual.startDate || '',
                        endDate: qual.endDate || ''
                    }))
                    : [{ university: '', qualification: '', startDate: '', endDate: '' }],
                specializations: doctorData.specializations && Array.isArray(doctorData.specializations)
                    ? doctorData.specializations.map(spec => ({
                        specialization: spec.specialization || '',
                        startDate: spec.startDate || '',
                        endDate: spec.endDate || ''
                    }))
                    : [{ specialization: '', startDate: '', endDate: '' }],
                time_slots: doctorData.time_slots
                    ? doctorData.time_slots.map(time => (
                        {
                            day: time.day || '',
                            startTime: time.startTime || '',
                            endTime: time.endTime || ''
                        }
                    ))
                    : [{ day: '', startTime: '', endTime: '' }],
                latitude: doctorData.latitude || '',
                longitude: doctorData.longitude || '',
            });
        }
    }, [doctorData]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onload = () => setImageSrc(reader.result);
        reader.readAsDataURL(file);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,

            [e.target.name]: e.target.value

        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = new FormData();
            data.append('first_name', formData.firstName);
            data.append('last_name', formData.lastName);
            data.append('email', formData.email);
            data.append('phone', formData.phone);
            data.append('doctor_type', formData.doctorType);
            data.append('gender', formData.gender);
            data.append('about', formData.about);
            data.append('address', formData.address);
            data.append('city', formData.city);
            data.append('state', formData.state);
            data.append('zip', formData.zip);
            data.append('latitude', formData.latitude);
            data.append('longitude', formData.longitude);

            // Append experience array to FormData
            formData.experience.forEach((exp, index) => {
                data.append(`experience[${index}][hospital]`, exp.hospital);
                data.append(`experience[${index}][position]`, exp.position);
                data.append(`experience[${index}][startDate]`, exp.startDate);
                data.append(`experience[${index}][endDate]`, exp.endDate);
            });

            // Append qualification array to FormData
            formData.qualification.forEach((qual, index) => {
                data.append(`qualification[${index}][university]`, qual.university);
                data.append(`qualification[${index}][qualification]`, qual.qualification);
                data.append(`qualification[${index}][startDate]`, qual.startDate);
                data.append(`qualification[${index}][endDate]`, qual.endDate);
            });

            // Append specialization array to FormData
            formData.specializations.forEach((spec, index) => {
                data.append(`specializations[${index}][specialization]`, spec.specialization);
                data.append(`specializations[${index}][startDate]`, spec.startDate);
                data.append(`specializations[${index}][endDate]`, spec.endDate);
            });

            // Append time_slots array to FormData only if there are time slots
            if (formData.time_slots && formData.time_slots.length > 0) {
                formData.time_slots.forEach((slot, index) => {
                    data.append(`time_slots[${index}][day]`, slot.day);
                    data.append(`time_slots[${index}][startTime]`, slot.startTime);
                    data.append(`time_slots[${index}][endTime]`, slot.endTime);
                });
            }



            // Append profile image only if an image is selected
            if (imageSrc && croppedAreaPixels) {
                const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels); // Get the cropped image blob
                if (croppedImage) {
                    data.append('profile_img', croppedImage, 'croppedImage.jpg');
                }
            }

            // Debugging: Check FormData content (optional)
            for (let pair of data.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            // Make the API request
            axios.put(
                `${process.env.REACT_APP_API_URL}/doctor/updatedoctor`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then(response => {
                    console.log(response.data);
                    fetchDoctorData();
                    // Close modal elements (optional)
                    document.getElementById("closeProfileCanvas").click();
                    document.getElementById("closeEduCanvas").click();
                    document.getElementById("closeLocCanvas").click();
                    document.getElementById("closeExpCanvas").click();
                    document.getElementById('closeSpeCanvas').click();

                    toast.success("Profile Updated Successfully");
                    navigate('/profile'); // Navigate or refresh to update UI
                });

        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message);
        }
    };



    const formatTime = (timeString) => {
        // Assume timeString is in 'HH:mm:ss' format
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };


    // reset doctor password api call function here 
    const resetPassword = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) return toast.error('Password do not match');
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/doctor/reset/password`,
                { password },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            toast.success("Password change successfully");
        } catch (error) {
            toast.error("Failed to change password")
        }
    }


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    if (!doctorData) {
        return <div>Loading...</div>; // Loading state
    }

    // location access api 
    const fetchCurrentLocation = () => {
        setIsLocating(true);
        setLocationError("");

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
                        .then((response) => response.json())
                        .then((data) => {
                            const { address } = data;
                            setFormData((prevData) => ({
                                ...prevData,
                                address: `${address.road || ""}, ${address.suburb || ""}`,
                                city: address.city || address.town || address.village || "",
                                state: address.state || "",
                                zip: address.postcode || "",
                                latitude: latitude.toString(),
                                longitude: longitude.toString()
                            }));
                            setIsLocating(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching location data:", error);
                            setLocationError("Failed to fetch address from location.");
                            setIsLocating(false);
                        });
                },
                (error) => {
                    console.error("Geolocation error:", error);
                    setLocationError("Unable to access your location.");
                    setIsLocating(false);
                }
            );
        } else {
            setLocationError("Geolocation is not supported by your browser.");
            setIsLocating(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Doctor Profile - Doctor BharateClinics </title>
            </Helmet>
            {/* Header */}
            <div className="row mt-10" >
                <div className="col-12">
                    <div className="card mb-6">
                        <div className="user-profile-header-banner">
                            <img
                                src="./img/pages/profile-banner.png"
                                alt="Banner image"
                                className="rounded-top"
                            />
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-5">
                            <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                <img
                                    src={doctorData.profile_img || './default-profile.png'} // Fallback image
                                    alt="user image"
                                    className="d-block h-auto ms-0 ms-sm-5 rounded-4 user-profile-img"
                                />
                            </div>
                            <div className="flex-grow-1 mt-4 mt-sm-12">
                                <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-6">
                                    <div className="user-profile-info">
                                        <h4 className="mb-2 text-capitalize">
                                            {doctorData.first_name || 'First Name'} {doctorData.last_name || 'Last Name'}
                                        </h4>
                                        <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
                                            <li className="list-inline-item">
                                                <i className="ri-admin-line me-2 ri-24px" />
                                                <span className="fw-medium">Doctor</span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="ri-map-pin-line me-2 ri-24px" />
                                                <span className="fw-medium">{doctorData.city || 'City'}</span>
                                            </li>
                                            <li className="list-inline-item">
                                                <i className="ri-calendar-line me-2 ri-24px" />
                                                <span className="fw-medium">
                                                    Joined {doctorData.created_at ?
                                                        new Date(doctorData.created_at).toLocaleString('default', { month: 'long' }) + ' ' + new Date(doctorData.created_at).getFullYear()
                                                        : 'N/A'}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end my-3">
                                        <a href="javascript:void(0)" className={`btn btn-${doctorData.verified ? 'success' : 'outline-success'} me-3`}>
                                            <i className="ri-user-follow-line ri-16px me-2" />
                                            {doctorData.verified ? 'Verified' : 'Not Verified'}
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="nav-align-top">
                        <ul className="nav nav-pills flex-column flex-sm-row mb-6">
                            {/* Profile Tab */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('profile')}
                                >
                                    <i className="ri-user-3-line me-2" />
                                    Profile
                                </a>
                            </li>

                            {/* Experience Tab */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'experience' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('experience')}
                                >
                                    <i class="ri-stethoscope-line me-2"></i>
                                    Experience
                                </a>
                            </li>

                            {/* Location Tab */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'location' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('location')}
                                >
                                    <i class="ri-map-pin-line me-2"></i>
                                    Location
                                </a>
                            </li>

                            {/* Education Tab */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'education' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('education')}
                                >
                                    <i class="ri-school-line me-2"></i>
                                    Education
                                </a>
                            </li>
                            {/* specialization  */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'specialization' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('specialization')}
                                >
                                    <i class="ri-school-line me-2"></i>
                                    Specialization
                                </a>
                            </li>

                            {/* password  */}
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                                    href="javascript:void(0);"
                                    onClick={() => handleTabClick('security')}
                                >
                                    <i className="ri-lock-password-line me-2"></i>
                                    Security
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>


                {activeTab === 'profile' && (
                    <div className="card mb-6">
                        <div className="card-body">
                            <div className='d-flex justify-content-between'>
                                <small className="card-text text-uppercase text-muted small">
                                    Profile
                                </small>
                                <button
                                    className="btn btn-label-primary me-2"
                                    data-bs-target="#offcanvasAddUser"
                                    data-bs-toggle="offcanvas"
                                >
                                    <i className="ri-pencil-line me-2"></i>
                                    Edit
                                </button>
                            </div>
                            <ul className="list-unstyled my-3 py-1">
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-user-3-line ri-24px" />
                                    <span className="fw-medium mx-2 ">Full Name:</span>{" "}
                                    <span className='text-capitalize'>{`${doctorData.first_name || ''} ${doctorData.last_name || ''}`}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-star-smile-line ri-24px" />
                                    <span className="fw-medium mx-2">Role:</span>{" "}
                                    <span className='text-uppercase'>{doctorData.doctor_type || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-mail-line ri-24px" />
                                    <span className="fw-medium mx-2">Email:</span>{" "}
                                    <span>{doctorData.email || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-phone-line ri-24px" />
                                    <span className="fw-medium mx-2">Phone:</span>{" "}
                                    <span>{doctorData.phone || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-user-3-line ri-24px" />

                                    <span className="fw-medium mx-2">Gender:</span>{" "}
                                    <span>{doctorData.gender || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i class="ri-money-rupee-circle-line"></i>

                                    <span className="fw-medium mx-2">Fee:</span>{" "}
                                    <span>{doctorData.price || 'Not Specified'}</span>
                                </li>

                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-information-line ri-24px" />
                                    <span className="fw-medium mx-2">Mci_Number:</span>{" "}
                                    <span>{doctorData.mci_number || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-information-line ri-24px" />
                                    <span className="fw-medium mx-2">Scheduling:</span>{" "}
                                    <span>
                                        {" "}{doctorData.time_slots && doctorData.time_slots.length > 0
                                            ? doctorData.time_slots.map((slot, index) => {
                                                // Parse the JSON string
                                                const timeSlot = JSON.parse(slot);
                                                return (
                                                    <div key={index}>
                                                        {`${timeSlot.day} : ${formatTime(timeSlot.startTime)} - ${formatTime(timeSlot.endTime)}`}
                                                    </div>
                                                );
                                            })
                                            : 'Not Specified'}
                                    </span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-information-line ri-24px" />
                                    <span className="fw-medium mx-2">About:</span>{" "}
                                    <span>{doctorData.about || 'Not Specified'}</span>
                                </li>
                                {/* Add other details here */}
                            </ul>

                        </div>
                    </div>
                )}
                {/* experience tab  */}
                {activeTab === 'experience' && (
                    <div className="card mb-6">
                        <div className="card-body">
                            <div className='d-flex justify-content-between'>

                                <small className="card-text text-uppercase text-muted small">
                                    Experience
                                </small>
                                <button
                                    className="btn btn-label-primary me-2 mb-1"
                                    data-bs-target="#offcanvasAddUserExp"
                                    data-bs-toggle="offcanvas"
                                >
                                    <i className="ri-pencil-line me-2"></i>
                                    Edit
                                </button>
                            </div>
                            <div className='card-datatable table-responsive'>

                                {doctorData.experience && doctorData.experience.length > 0 ? (
                                    <table className="datatables-basic table table-bordered dataTable no-footer dtr-column">

                                        <thead>
                                            <tr>
                                                <th>Hospital</th>
                                                <th>Position</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {doctorData.experience.map((exp, index) => (
                                                <tr key={index}>
                                                    <td>{exp.hospital || 'Not Specified'}</td>
                                                    <td>{exp.position || 'Not Specified'}</td>
                                                    <td>{exp.startDate ? formatDate(exp.startDate) : 'Not Specified'}</td>
                                                    <td>{exp.endDate ? formatDate(exp.endDate) : 'Not Specified'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No experience details available</p>
                                )}
                            </div>
                        </div>
                    </div>


                )}
                {/* location tab  */}
                {activeTab === 'location' && (
                    <div className="card mb-6">
                        <div className="card-body">
                            <div className='d-flex justify-content-between'>

                                <small className="card-text text-uppercase text-muted small">
                                    Location
                                </small>
                                <button
                                    className="btn btn-label-primary me-2"
                                    data-bs-target="#offcanvasAddUserLoc"
                                    data-bs-toggle="offcanvas"
                                >
                                    <i className="ri-pencil-line me-2"></i>
                                    Edit
                                </button>
                            </div>
                            <ul className="list-unstyled my-3 py-1">
                                {/* Display Address Details */}
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-map-pin-line ri-24px" />
                                    <span className="fw-medium mx-2">Address:</span>{" "}
                                    <span>{doctorData.address || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-building-line ri-24px" />
                                    <span className="fw-medium mx-2">City:</span>{" "}
                                    <span>{doctorData.city || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-building-line ri-24px" />
                                    <span className="fw-medium mx-2">State:</span>{" "}
                                    <span>{doctorData.state || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-code-line ri-24px" />
                                    <span className="fw-medium mx-2">ZIP Code:</span>{" "}
                                    <span>{doctorData.zip || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-information-line ri-24px" />
                                    <span className="fw-medium mx-2">Latitude:</span>{" "}
                                    <span>{doctorData.latitude || 'Not Specified'}</span>
                                </li>
                                <li className="d-flex align-items-center mb-4">
                                    <i className="ri-information-line ri-24px" />
                                    <span className="fw-medium mx-2">Longitude:</span>{" "}
                                    <span>{doctorData.longitude || 'Not Specified'}</span>
                                </li>
                            </ul>

                        </div>
                    </div>
                )}
                {activeTab === 'education' && (
                    <div className="card mb-6">
                        <div className="card-body">
                            <div className='d-flex justify-content-between'>

                                <small className="card-text text-uppercase text-muted small">
                                    Education
                                </small>
                                <button
                                    className="btn btn-label-primary me-2"
                                    data-bs-target="#offcanvasAddUserEdu"
                                    data-bs-toggle="offcanvas"
                                >
                                    <i className="ri-pencil-line me-2"></i>
                                    Edit
                                </button>
                            </div>
                            <div className="card-datatable table-responsive">
                                <h5>Qualifications</h5>
                                <table className="datatables-basic table table-bordered dataTable no-footer dtr-column">
                                    <thead>
                                        <tr>
                                            <th>University</th>
                                            <th>Qualification</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {doctorData.qualification && doctorData.qualification.length > 0 ? (
                                            doctorData.qualification.map((qual, index) => (
                                                <tr key={index}>
                                                    <td>{qual.university || 'Not Specified'}</td>
                                                    <td>{qual.qualification || 'Not Specified'}</td>
                                                    <td>{formatDate(qual.startDate)}</td>
                                                    <td>{formatDate(qual.endDate)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No qualification details available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                )}
                {activeTab === 'specialization' && (
                    <div className="col-12">
                        <div className="card mb-6">
                            <div className="card-body">
                                <div className='d-flex justify-content-between'>

                                    <small className="card-text text-uppercase text-muted small">
                                        Specialization
                                    </small>
                                    <button
                                        className="btn btn-label-primary me-2"
                                        data-bs-target="#offcanvasAddUserSpe"
                                        data-bs-toggle="offcanvas"
                                    >
                                        <i className="ri-pencil-line me-2"></i>
                                        Edit
                                    </button>
                                </div>
                                <div className="card-datatable table-responsive">
                                    <h5>Specializations</h5>
                                    <table className="datatables-basic table table-bordered dataTable no-footer dtr-column">
                                        <thead>
                                            <tr>
                                                <th>Specialization</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {doctorData.specializations && doctorData.specializations.length > 0 ? (
                                                doctorData.specializations.map((spec, index) => (
                                                    <tr key={index}>
                                                        <td>{spec.specialization || 'Not Specified'}</td>
                                                        <td>{formatDate(spec.startDate)}</td>
                                                        <td>{formatDate(spec.endDate)}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3">No specialization details available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'security' && (
                    <div className="col-12">
                        <div className="card mb-6">
                            <div className="card-body">
                                <div className='d-flex justify-content-between mb-6'>

                                    <small className="card-text text-uppercase text-muted small">
                                        Security
                                    </small>

                                </div>
                                <div className="mb-6">
                                    <h5 className="">Change Password</h5>
                                    <div className="">
                                        <form onSubmit={(e) => resetPassword(e)}>
                                            <div className="alert alert-warning alert-dismissible" role="alert">
                                                <h5 className="alert-heading mb-1">
                                                    Ensure that these requirements are met
                                                </h5>
                                                <span>Minimum 8 characters long, uppercase &amp; symbol</span>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="alert"
                                                    aria-label="Close"
                                                />
                                            </div>
                                            <div className="row gx-5">
                                                <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                                                    <div className="input-group input-group-merge">
                                                        <div className="form-floating form-floating-outline">
                                                            <input
                                                                type="password"
                                                                id="newPassword"
                                                                name="newPassword"
                                                                className="form-control "
                                                                placeholder="············"
                                                                required=""
                                                                defaultValue=""
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            />
                                                            <label htmlFor="newPassword">New Password</label>
                                                            <div className="invalid-feedback" />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                                                    <div className="input-group input-group-merge">
                                                        <div className="form-floating form-floating-outline">
                                                            <input
                                                                type="password"
                                                                id="confirmPassword"
                                                                name="confirmPassword"
                                                                className="form-control "
                                                                placeholder="············"
                                                                required=""
                                                                defaultValue=""
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                            />
                                                            <label htmlFor="confirmPassword">Confirm New Password</label>
                                                            <div className="invalid-feedback" />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-primary me-2">
                                                        Change Password
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
                {/* edit profile model  */}
                <div className="offcanvas offcanvas-end canvas-width" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header border-bottom">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Doctor Profile</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                        <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={(e) => handleSubmit(e)}>
                            {/* First Name and Last Name */}
                            <div className="form-floating form-floating-outline mb-5">
                                {/* First Name Field */}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-firstname"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                />
                                <label htmlFor="update-user-firstname">First Name</label>
                            </div>

                            <div className="form-floating form-floating-outline mb-5">
                                {/* Last Name Field */}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-lastname"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                                />
                                <label htmlFor="update-user-lastname">Last Name</label>
                            </div>


                            {/* Email */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="update-user-email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <label htmlFor="update-user-email">Email</label>
                            </div>

                            {/* Phone */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-contact"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <label htmlFor="update-user-contact">Contact</label>
                            </div>

                            {/* Gender */}
                            <div className="form-floating form-floating-outline mb-5">
                                <select
                                    className="form-select"
                                    id="update-user-gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                                <label htmlFor="update-user-gender">Gender</label>
                            </div>

                            {/* About */}
                            <div>
                                <div className="form-floating form-floating-outline mb-5">
                                    <textarea
                                        className="form-control"
                                        id="update-user-about"
                                        name="about"
                                        value={formData.about}
                                        onChange={handleChange}
                                        style={{ height: "100px" }}
                                    />
                                    <label htmlFor="update-user-about">About</label>
                                </div>
                            </div>
                            {/* Doctor Type */}
                            <div className="form-floating form-floating-outline mb-5">
                                <select
                                    className="form-select"
                                    id="update-user-doctor-type"
                                    name="doctorType"
                                    value={formData.doctorType}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Doctor Type</option>
                                    <option value="general">General</option>
                                    <option value="specialist">Specialist</option>
                                    {/* Add more doctor types as needed */}
                                </select>
                                <label htmlFor="update-user-doctor-type">Doctor Type</label>
                            </div>

                            {/* Time Slots Field */}
                            <div className="mb-5">
                                <label className="form-label">Time Slots</label>

                                {/* Render time slots only if user has manually added them */}
                                {formData.time_slots.length > 0 ? (
                                    formData.time_slots.map((slot, index) => (
                                        <div key={index} className="d-flex flex-column flex-sm-row align-items-center mb-3">
                                            <div className="flex-fill me-2 mb-2 mb-sm-0"> {/* Mobile column spacing */}
                                                {/* Day Field */}
                                                <select
                                                    className="form-select"
                                                    name="day"
                                                    value={slot.day || ""} // Set default value if undefined
                                                    onChange={(e) => handleTimeSlotChange(e, index, 'day')}
                                                >
                                                    <option value="">Select Day</option>
                                                    <option value="Monday">Monday</option>
                                                    <option value="Tuesday">Tuesday</option>
                                                    <option value="Wednesday">Wednesday</option>
                                                    <option value="Thursday">Thursday</option>
                                                    <option value="Friday">Friday</option>
                                                    <option value="Saturday">Saturday</option>
                                                    <option value="Sunday">Sunday</option>
                                                </select>
                                            </div>

                                            <div className="flex-fill me-2 mb-2 mb-sm-0">
                                                {/* Start Time Field */}
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name="startTime"
                                                    value={slot.startTime || ""} // Set default value if undefined
                                                    onChange={(e) => handleTimeSlotChange(e, index, 'startTime')}
                                                />
                                            </div>

                                            <div className="flex-fill me-2 mb-2 mb-sm-0">
                                                {/* End Time Field */}
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name="endTime"
                                                    value={slot.endTime || ""} // Set default value if undefined
                                                    onChange={(e) => handleTimeSlotChange(e, index, 'endTime')}
                                                />
                                            </div>

                                            {/* Remove Time Slot */}
                                            <button
                                                type="button"
                                                className="btn btn-danger mb-2 mb-sm-0"
                                                onClick={() => removeTimeSlot(index)}
                                            >
                                                Remove
                                            </button>

                                        </div>
                                    ))
                                ) : (
                                    <p>No time slots available. Please add a time slot.</p>
                                )}

                                {/* Add Time Slot Button */}
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={addTimeSlot}
                                >
                                    Add Time Slot
                                </button>

                            </div>


                            {/* Profile Image */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="file"
                                    className="form-control"
                                    id="update-user-profile-img"
                                    name="profile_img"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="update-user-profile-img">Profile Image</label>
                            </div>

                            {imageSrc && (
                                <div className="crop-container" style={{ position: 'relative', width: '100%', height: '400px' }}>
                                    <Cropper
                                        image={imageSrc}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={1} // 1:1 aspect ratio for square images
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                </div>
                            )}

                            {/* Ensure Save Changes button is visible and placed below the crop section */}
                            {imageSrc && (
                                <div className="text-center mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-label-primary mb-2"
                                        onClick={handleSubmit}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            )}


                            {/* Submit and Reset Buttons */}
                            <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
                            <button type="reset" id="closeProfileCanvas" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                        </form>
                    </div>
                </div>

                {/* experience model edit  */}
                <div className="offcanvas offcanvas-end canvas-width" tabIndex={-1} id="offcanvasAddUserExp" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header border-bottom">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Doctor Experience</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                        <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={(e) => handleSubmit(e)}>
                            {/* Existing Fields... */}

                            {/* Experience Section */}
                            {/* Experience Section */}
                            <div className="experience-section mb-5">
                                <h6 className="mb-3">Experience</h6>
                                {Array.isArray(formData.experience) && formData.experience.map((exp, index) => (
                                    <div key={index} className="experience-item mb-4">
                                        {/* Hospital */}
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="hospital"
                                                value={exp.hospital}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                                placeholder="Hospital"
                                            />
                                            <label htmlFor={`experience-hospital-${index}`}>Hospital</label>
                                        </div>

                                        {/* Position */}
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="position"
                                                value={exp.position}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                                placeholder="Position"
                                            />
                                            <label htmlFor={`experience-position-${index}`}>Position</label>
                                        </div>

                                        {/* Start Date */}
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="startDate"
                                                value={exp.startDate}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                            />
                                            <label htmlFor={`experience-startdate-${index}`}>Start Date</label>
                                        </div>

                                        {/* End Date */}
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="endDate"
                                                value={exp.endDate || ''}
                                                onChange={(e) => handleExperienceChange(index, e)}
                                            />
                                            <label htmlFor={`experience-enddate-${index}`}>End Date (optional)</label>
                                        </div>

                                        {/* Remove Experience Button */}
                                        <button type="submit" className="btn btn-label-danger me-sm-3 me-1" onClick={() => handleRemoveExperience(index)}>

                                            Remove Experience
                                        </button>
                                    </div>
                                ))}

                                {/* Add Experience Button */}
                                <button type="button" className="btn btn-label-primary" onClick={handleAddExperience}>
                                    Add Experience
                                </button>
                            </div>


                            {/* Submit and Reset Buttons */}
                            <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
                            <button type="reset" id="closeExpCanvas" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                        </form>
                    </div>
                </div>


                {/* location edit  */}
                <div
                    className="offcanvas offcanvas-end canvas-width"
                    tabIndex={-1}
                    id="offcanvasAddUserLoc"
                    aria-labelledby="offcanvasAddUserLabel"

                >
                    <div className="offcanvas-header border-bottom">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Doctor Location</h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                        <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={(e) => handleSubmit(e)}>
                            {/* Address */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-address"
                                    name="address"
                                    value={formData.address}
                                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                />
                                <label htmlFor="update-user-address">Address</label>
                            </div>

                            {/* City */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-city"
                                    name="city"
                                    value={formData.city}
                                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                />
                                <label htmlFor="update-user-city">City</label>
                            </div>

                            {/* State */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-state"
                                    name="state"
                                    value={formData.state}
                                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                                />
                                <label htmlFor="update-user-state">State</label>
                            </div>

                            {/* Zip */}
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-zip"
                                    name="zip"
                                    value={formData.zip}
                                    onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
                                />
                                <label htmlFor="update-user-zip">Zip Code</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-latitude"
                                    name="latitude"
                                    value={formData.latitude}
                                    onChange={(e) => setFormData({ ...formData, latitude: e.target.value })}
                                />
                                <label htmlFor="update-user-latitude">Latitude</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="update-user-longitude"
                                    name="longitude"
                                    value={formData.longitude}
                                    onChange={(e) => setFormData({ ...formData, longitude: e.target.value })}
                                />
                                <label htmlFor="update-user-longitude">Longitude</label>
                            </div>

                            {/* location icon  */}
                            <div className="d-flex align-items-center gap-2 mb-5" onClick={fetchCurrentLocation}
                                aria-disabled={isLocating}>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded-3">
                                        <i className="ri-focus-3-line ri-24px"></i>
                                    </div>
                                </div>
                                {isLocating ? "Locating..." : "Use My Current Location"}
                            </div>

                            {/* Submit and Reset Buttons */}
                            <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
                            <button
                                type="reset"
                                id='closeLocCanvas'
                                className="btn btn-outline-secondary"
                                data-bs-dismiss="offcanvas"
                            >
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>


                {/* Eduction edit model  */}
                <div className="offcanvas offcanvas-end canvas-width" tabIndex={-1} id="offcanvasAddUserEdu" aria-labelledby="offcanvasAddUserLabel">
                    <div className="offcanvas-header border-bottom">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Doctor Education</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                        <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={(e) => handleSubmit(e)}>
                            {/* Existing form fields here */}

                            {/* Qualifications */}
                            <div className="mb-5 " >
                                <h6>Qualifications</h6>
                                {formData.qualification.map((qual, index) => (
                                    <div key={index} className="mb-3">
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`update-user-qualification-university-${index}`}
                                                name="university"
                                                value={qual.university}
                                                onChange={(e) => handleQualificationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-qualification-university-${index}`}>University</label>
                                        </div>
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`update-user-qualification-name-${index}`}
                                                name="qualification"
                                                value={qual.qualification}
                                                onChange={(e) => handleQualificationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-qualification-name-${index}`}>Qualification</label>
                                        </div>
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id={`update-user-qualification-startDate-${index}`}
                                                name="startDate"
                                                value={qual.startDate}
                                                onChange={(e) => handleQualificationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-qualification-startDate-${index}`}>Start Date</label>
                                        </div>
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id={`update-user-qualification-endDate-${index}`}
                                                name="endDate"
                                                value={qual.endDate}
                                                onChange={(e) => handleQualificationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-qualification-endDate-${index}`}>End Date</label>
                                        </div>
                                        <button type="button" className="btn btn-label-danger me-sm-3 me-1" onClick={() => handleRemoveQualification(index)}>Remove</button>
                                    </div>
                                ))}
                                <button type="button" className="btn btn-label-primary" onClick={handleAddQualification}>Add Qualification</button>
                            </div>



                            {/* Submit and Reset Buttons */}
                            <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
                            <button type="reset" id='closeEduCanvas' className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                        </form>
                    </div>
                </div>

                {/* specialization model  */}
                <div className="offcanvas offcanvas-end canvas-width" tabIndex={-1} id="offcanvasAddUserSpe" aria-labelledby="offcanvasAddUserLabel" >
                    <div className="offcanvas-header border-bottom">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Doctor Specialization</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                        <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={(e) => handleSubmit(e)}>
                            {/* Existing form fields here */}

                            {/* Specializations */}
                            <div className="mb-5">
                                <h6>Specializations</h6>
                                {formData.specializations.map((spec, index) => (
                                    <div key={index} className="mb-3">
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`update-user-specialization-${index}`}
                                                name="specialization"
                                                value={spec.specialization}
                                                onChange={(e) => handleSpecializationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-specialization-${index}`}>Specialization</label>
                                        </div>
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id={`update-user-specialization-startDate-${index}`}
                                                name="startDate"
                                                value={spec.startDate}
                                                onChange={(e) => handleSpecializationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-specialization-startDate-${index}`}>Start Date</label>
                                        </div>
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id={`update-user-specialization-endDate-${index}`}
                                                name="endDate"
                                                value={spec.endDate}
                                                onChange={(e) => handleSpecializationChange(index, e)}
                                            />
                                            <label htmlFor={`update-user-specialization-endDate-${index}`}>End Date</label>
                                        </div>
                                        <button type="button" className="btn btn-label-danger me-sm-3 me-1" onClick={() => handleRemoveSpecialization(index)}>Remove</button>
                                    </div>
                                ))}
                                <button type="button" className="btn btn-label-primary" onClick={handleAddSpecialization}>Add Specialization</button>
                            </div>

                            {/* Submit and Reset Buttons */}
                            <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
                            <button type="reset" id='closeSpeCanvas' className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                        </form>
                    </div>
                </div>

            </div>
        </>
    );
}
