import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import Remarks from './remarks';
import { useAuth } from '../../components/utils/loggedin';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export default function Prescription() {
    const [remarks, setRemarks] = useState("");
    const [spo2, setSpo2] = useState("");
    const [bpSystolic, setBpSystolic] = useState('');
    const [bpDiastolic, setBpDiastolic] = useState('');
    const [bloodSugar, setBloodSugar] = useState('');
    const [heartRate, setHeartRate] = useState('');
    const [temperature, setTemperature] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [consultantType, setConsultantType] = useState('');
    const [aadhaarCardNumber, setAadhaarCardNumber] = useState(''); // State for Aadhaar card number
    const [aadhaarError, setAadhaarError] = useState(''); // State for Aadhaar error message
    const location = useLocation();
    const { doctorData, token } = useAuth();
    const { user_id } = location.state || {};
    const [data, setData] = useState(null);
    console.log(user_id)
    console.log(doctorData)
    console.log(location);

    useEffect(() => {
        const fetchPrescriptionData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prescription/${user_id}`, {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE
                    }
                });
                setData(response.data);
            } catch (error) {
                console.error('There was an error fetching the prescription!', error);
            }
        };
        fetchPrescriptionData();
    }, [user_id]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (user_id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/getuserbyid/${user_id}`, {
                        headers: {
                            "Signature": process.env.REACT_APP_API_SIGNATURE
                        }
                    });
                    setAadhaarCardNumber(response.data?.user_details?.aadhaar_card_number);
                } catch (error) {
                    toast.error(error?.response?.data?.message || "Failed to fetch user data");
                }
            }
        };
        fetchUserData();
    }, [user_id]);


    const CreateDoctorPrescription = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/prescription/create`, {
                remarks,
                spo2,
                bp_systolic: bpSystolic,
                bp_diastolic: bpDiastolic,
                blood_sugar: bloodSugar,
                heart_rate: heartRate,
                temperature,
                weight,
                height,
                aadhaar_card_number: aadhaarCardNumber,
                consultant_type: consultantType

            }, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            toast.success("Prescription created successfully");
            console.log(response.data); // Log response or handle success
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || "Error creating prescription");

        }
    }

    // Validate Aadhaar card number
    const handleAadhaarChange = (e) => {
        const value = e.target.value;
        setAadhaarCardNumber(value);

        if (value.length !== 12) {
            setAadhaarError("Aadhaar card number must be 12 digits.");
        } else {
            setAadhaarError("");
        }
    }

    const handleConsultantTypeChange = (e) => {
        setConsultantType(e.target.value);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <Helmet>
                <title>Prescription - Doctor Bharat eClinics </title>
            </Helmet>


            <div className="shadow m-6 rounded p-5 bg-white border border-primary mt-10" style={{ width: '50rem' }}>
                {data ? (
                    <>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <img src="./img/logo.png" height={150} alt="Logo" />
                                <h4>Bharat eClinics</h4>
                            </div>
                            <div>
                                <p className='mb-1'>
                                    Clinic Rep: <span className='fw-semibold'>{data.prescription?.Clinic?.first_name + ' ' + data.prescription?.Clinic?.last_name}</span> <br />
                                    Email: <span className='fw-semibold'>{data.prescription?.Clinic?.email}</span> <br />
                                    Phone: <span className='fw-semibold'>{data.prescription?.Clinic?.phone}</span>
                                </p>
                                <div className='mt-3'>
                                    <Link to={data?.prescription?.pres_url} target="_blank" className="badge bg-lighter rounded-3">
                                        <img src="/img/icons/misc/pdf.png" alt="img" width="15" className="me-2" />
                                        <span className="h6 mb-0 text-body">Download Pdf</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="divider divider-primary">
                            <div className="divider-text">Prescription</div>
                        </div>
                        <div className="row gap-3">
                            <div className="border col-4">
                                <h4>Patient Details:</h4>
                                <p>Name: {`${data.prescription?.patient?.first_name} ${data.prescription?.patient?.last_name}`}</p>
                                <p>Age: {data.userDetails?.age} years</p>
                                <p>Gender: {data?.userDetails?.gender}</p>
                                <p>Date: {formatDate(data.prescription?.createdAt)}</p>
                                <h4>Patient Reports:</h4>
                                <p>Blood Pressure: {data.prescription?.bp_systolic}/{data.prescription?.bp_diastolic}</p>
                                <p>Blood Sugar: {data.prescription?.blood_sugar} mg/dl</p>
                                <p>Weight: {data.prescription?.weight} kg</p>
                                <p>Temperature: {data.prescription?.temperature} °F</p>
                                <p>Pulse:  bpm</p>
                                <p>Respiratory Rate: {data.prescription?.spo2}%</p>
                            </div>
                            <div className="border col-7">
                                <img className='m-4' height={50} width={50} src="./img/rxicon.png" alt="Rx Icon" />
                                <Remarks prescriptionId={data.prescription?.id} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>

                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <img src="./img/logo.png" height={150} alt="Logo" />
                                <h4>Bharat eClinics</h4>
                            </div>
                            <div>
                                <p className='mb-1'>
                                    Doctor: <span className='fw-semibold'>{doctorData?.first_name + " " + doctorData?.last_name}</span> <br />
                                    Email: <span className='fw-semibold'>{doctorData?.email}</span> <br />
                                    Phone: <span className='fw-semibold'>{doctorData?.phone}</span>
                                </p>
                            </div>
                        </div>
                        <div className="divider divider-primary">
                            <div className="divider-text">Prescription</div>
                        </div>
                        <div className="row gap-3">
                            <div className="border col-4">
                                <h4>Patient Details:</h4>
                                {/* <p>Name: </p>
                                    <p>Age: years</p>
                                    <p>Gender: </p>
                                    <p>Date:</p> */}

                                <h5>Aadhaar Card Number:</h5>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingAadhaar"
                                        placeholder="Aadhaar Card Number"
                                        value={aadhaarCardNumber}
                                        onChange={handleAadhaarChange}
                                    />
                                    <label htmlFor="floatingAadhaar">Aadhaar Card Number</label>
                                </div>
                                {aadhaarError && <p className="text-danger">{aadhaarError}</p>} {/* Display error message if any */}
                                <h5>Consultant Type</h5>
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control"
                                        id="floatingConsultantType"
                                        value={consultantType} // state variable for the selected option
                                        onChange={handleConsultantTypeChange} // handle change event
                                    >
                                        <option value="">Select Consultant Type</option>
                                        <option value="online">Online</option>
                                        <option value="offline">Offline</option>
                                    </select>
                                    <label htmlFor="floatingConsultantType">Consultant Type</label>
                                </div>
                                <h4>Patient Reports:</h4>
                                <p>Blood Pressure:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingBloodPressure"
                                        placeholder="120/80"
                                        value={`${bpSystolic}/${bpDiastolic}`}
                                        onChange={(e) => {
                                            const [systolic, diastolic] = e.target.value.split('/');
                                            setBpSystolic(Number(systolic));
                                            setBpDiastolic(Number(diastolic));
                                        }}
                                    />
                                    <label htmlFor="floatingBloodPressure">Blood Pressure (Systolic/Diastolic)</label>
                                </div>

                                <p>Blood Sugar:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingBloodSugar"
                                        placeholder="Blood Sugar (mg/dl)"
                                        value={bloodSugar}
                                        onChange={(e) => setBloodSugar(Number(e.target.value))}
                                    />
                                    <label htmlFor="floatingBloodSugar">Blood Sugar (mg/dl)</label>
                                </div>

                                <p>Weight:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingWeight"
                                        placeholder="Weight (kg)"
                                        value={weight}
                                        onChange={(e) => setWeight(Number(e.target.value))}
                                    />
                                    <label htmlFor="floatingWeight">Weight (kg)</label>
                                </div>

                                <p>Height:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingHeight"
                                        placeholder="Height (cm)"
                                        value={height}
                                        onChange={(e) => setHeight(Number(e.target.value))}
                                    />
                                    <label htmlFor="floatingHeight">Height (cm)</label>
                                </div>

                                <p>Temperature:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingTemperature"
                                        placeholder="Temperature (°F)"
                                        value={temperature}
                                        onChange={(e) => setTemperature(Number(e.target.value))}
                                    />
                                    <label htmlFor="floatingTemperature">Temperature (°F)</label>
                                </div>

                                <p>Heart Rate:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingHeartRate"
                                        placeholder="Heart Rate (bpm)"
                                        value={heartRate}
                                        onChange={(e) => setHeartRate(Number(e.target.value))}
                                    />
                                    <label htmlFor="floatingHeartRate">Heart Rate (bpm)</label>
                                </div>

                                <p>SPO2:</p>
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingSpo2"
                                        placeholder="SPO2 (%)"
                                        value={spo2}
                                        onChange={(e) => setSpo2(Number(e.target.value))}
                                    />
                                    <label htmlFor="floatingSpo2">SPO2 (%)</label>
                                </div>
                            </div>
                            <div className="border col-7">
                                <img className='m-4' height={50} width={50} src="./img/rxicon.png" alt="Rx Icon" />
                                <textarea
                                    className='w-100 p-2'
                                    rows={20}
                                    placeholder='Remarks'
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                            </div>
                        </div>

                        <button onClick={CreateDoctorPrescription} className="btn btn-primary mt-3">Submit Prescription</button>
                    </>


                )}
            </div>

        </>
    );
}
