import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";



const AllPatients = () => {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchName, setSearchName] = useState('');
    const [searchCardNumber, setSearchCardNumber] = useState('');
    const [searchId, setSearchId] = useState('');


    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/appointments/doctor`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Signature": process.env.REACT_APP_API_SIGNATURE
                    }
                });
                setAppointments(response.data);
            } catch (error) {
                setError(error);
                toast.error(error?.response?.data?.message)
            } finally {
                setLoading(false);
            }
        };

        fetchAppointments();
    }, [token]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('5 per page');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (label) => {
        setSelectedOption(label);
        const value = parseInt(label.split(' ')[0], 10);
        setItemsPerPage(value);
        setCurrentPage(1); // Reset to the first page when changing items per page
        setIsOpen(false);
    };

    // Extract unique user IDs
    const uniqueUserIds = Array.from(new Set(appointments.map(app => app.User?.id)));

    // Create a map to ensure only one appointment per unique user ID
    const uniqueAppointmentsMap = new Map();
    appointments.forEach(appointment => {
        if (!uniqueAppointmentsMap.has(appointment.User?.id)) {
            uniqueAppointmentsMap.set(appointment.User?.id, appointment);
        }
    });

    // Convert map to array
    const uniqueAppointments = Array.from(uniqueAppointmentsMap.values());

    // Pagination Logic
    const indexOfLastAppointment = currentPage * itemsPerPage;
    const indexOfFirstAppointment = indexOfLastAppointment - itemsPerPage;
    const currentAppointments = uniqueAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);

    // Filter Logic
    const filteredAppointments = currentAppointments.filter(appointment => {
        const fullName = (appointment.User?.first_name || '') + ' ' + (appointment.User?.last_name || '');
        const username = appointment.User?.username || '';
        const appointmentId = appointment.appointment_id || '';

        return (
            fullName.toLowerCase().includes(searchName.toLowerCase()) &&
            appointmentId.toLowerCase().includes(searchCardNumber.toLowerCase()) &&
            username.toLowerCase().includes(searchId.toLowerCase())
        );
    });

    const totalPages = Math.ceil(uniqueAppointments.length / itemsPerPage);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading appointments.</p>;

    const formatTime = (timeString) => {
        // Assume timeString is in 'HH:mm:ss' format
        const [hours, minutes] = timeString.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    function ViewPrescription(user_id) {
        navigate("/patients/prescription", { state: { user_id } });
    }

    return (
        <>
            <Helmet>
                <title>Patients - Doctor BharateClinics </title>
            </Helmet>
            <div className="card mt-10">
                <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Patients</h5>
                </div>
                <div className="card-datatable table-responsive">
                    <div className="dataTables_wrapper dt-bootstrap5 no-footer">


                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="DataTables_Table_0_length " >
                                    <label>
                                        Show{" "}
                                        <select
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                            value={itemsPerPage}
                                            onChange={(e) => {
                                                setItemsPerPage(Number(e.target.value));
                                                setCurrentPage(1); // Reset to the first page when items per page changes
                                            }}
                                        >
                                            <option value={7}>7</option>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>


                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Patient Id</th>
                                    <th>Appointment Number</th>
                                    <th>Name</th>
                                    <th>Aadhaar Card Number</th>
                                    <th>Arrival Time</th>
                                    <th>Departure Time</th>
                                    <th>Prescription</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Search by Patient Id"
                                            value={searchId}
                                            onChange={(e) => setSearchId(e.target.value)}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Search by Card Number"
                                            value={searchCardNumber}
                                            onChange={(e) => setSearchCardNumber(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Search by name"
                                            value={searchName}
                                            onChange={(e) => setSearchName(e.target.value)}
                                        />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {loading ? (
                                    <tr>
                                        <td colSpan="7">Loading...</td>
                                    </tr>
                                ) : filteredAppointments.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" className="text-center text-muted">
                                            No data found
                                        </td>
                                    </tr>
                                ) : (
                                    filteredAppointments.map((appointment, index) => (
                                        <tr key={appointment.User?.id}>
                                            <td>{indexOfFirstAppointment + index + 1}</td>
                                            <td>{appointment.User?.username}</td>
                                            <td>{appointment.appointment_id}</td>
                                            <td>
                                                {appointment.User?.first_name} {appointment.User?.last_name}
                                            </td>
                                            <td>{appointment.User?.user_detail?.aadhaar_card_number}</td>
                                            <td>{formatTime(appointment.arrival_time)}</td>
                                            <td>{formatTime(appointment.departure_time)}</td>
                                            <td>
                                                <div className='d-flex align-items-center mt-3'>
                                                    {appointment?.prescriptions?.length === 0 ? (
                                                        <span>No prescription</span>
                                                    ) : (
                                                        <Link to={appointment?.prescriptions[0]?.pres_url} target="_blank" className="badge bg-lighter rounded-3 me-3">
                                                            <img src="/img/icons/misc/pdf.png" alt="img" width="15" className="me-2" />
                                                            <span className="h6 mb-0 text-body">Download</span>
                                                        </Link>
                                                    )}

                                                    {/* Add Prescription Button */}
                                                    {/* <Link
                                                        to={{
                                                            pathname: '/prescription',
                                                            state: { user_id: appointment.User?.id },
                                                        }}
                                                        className="d-flex align-items-center cursor-pointer"
                                                    >
                                                        <span style={{ marginLeft: '5px', fontSize: "15px" }}>Add</span>
                                                    </Link> */}
                                                    <button onClick={() => ViewPrescription(appointment.User?.id)}>

                                                        <i className="ri-add-line"></i>
                                                    </button>

                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>


                        {/* Pagination controls */}
                        <nav className="flex justify-content-end mt-5 mx-2" aria-label="Page navigation">
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(1)}>
                                        <i className="tf-icon ri-skip-back-mini-line ri-20px" />
                                    </button>
                                </li>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                                    </button>
                                </li>
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                        <i className="tf-icon ri-skip-forward-mini-line ri-20px" />
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllPatients;
